<template>
  <div class="form__question">
    <div class="form__content">
      <legend class="quiz__subheading">To get this started, let&rsquo;s create an account.</legend>
      <div class="form__item form__item--text">
        <input type="password" name="password1" id="password1" placeholder="Password">
      </div>
      <div class="form__item form__item--text">
        <input type="password" name="password2" id="password2" placeholder="Password (again)">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
