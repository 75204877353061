import { cloudName, uploadPreset } from './cloudinaryConfig'

const baseUrl = `https://api.cloudinary.com/v1_1/${cloudName}`

// https://cloudinary.com/blog/guest_post/upload-multiple-images-to-cloudinary-with-filepond
export const makeUploadRequest = ({
  file,
  fieldName,
  progressCallback,
  successCallback,
  errorCallback
}) => {
  const url = `${baseUrl}/auto/upload`

  const formData = new FormData()
  formData.append(fieldName, file)
  formData.append('upload_preset', uploadPreset)

  const request = new XMLHttpRequest()
  request.open('POST', url)

  request.upload.onprogress = (e) => {
    progressCallback(e.lengthComputable, e.loaded, e.total)
  }

  request.onload = () => {
    if (request.status >= 200 && request.status < 300) {
      const { delete_token: deleteToken, secure_url: imageUrl } = JSON.parse(request.response)

      successCallback(deleteToken)
      file.url = imageUrl
    } else {
      errorCallback(request.responseText)
    }
  }

  request.send(formData)

  return () => {
    request.abort()
  }
}

export const makeDeleteRequest = ({
  token,
  successCallback,
  errorCallback
}) => {
  const url = `${baseUrl}/delete_by_token`

  const request = new XMLHttpRequest()
  request.open('POST', url)

  request.setRequestHeader('Content-Type', 'application/json')

  request.onload = () => {
    if (request.status >= 200 && request.status < 300) {
      successCallback()
    } else {
      errorCallback(request.responseText)
    }
  }
  request.send(JSON.stringify({ token }))
}
