<template>
  <div class="form__image">
    <div class="form__content">
      <img
        v-if="url"
        :src="url"
        :class="classes || ''"
        :style="config?.customCss || ''"
        :alt="config?.altTag || ''"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: undefined
    },
    classes: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  grid-column: span 12;
}

.fullWidth {
  width: 100%
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50% !important;
}

.centered--lower3em {
  position: relative;
  top: 3em;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50% !important;
}

.centeredAuto {
  margin: 0 auto;
  width: auto !important;
}
</style>
