<template>
  <fieldset class="form__heading form__question question-div">
    <div class="form__content">
      <div class="form__heading__content">
        <p :style="textAlign ? `text-align: ${textAlign};` : ''">
          <slot />
        </p>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  props: {
    textAlign: {
      type: String,
      default: null
    }
  }
}
</script>
