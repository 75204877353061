<template>
  <div class="form__question">
    <div class="form__content">
      <legend class="quiz__subheading" v-html="question.question" />
      <div class="form__item form__item--range">
        <div class="rangBTN"><button type="button" @click="decrementSlider"><i class="icon-minus"></i></button><span>1</span></div>
        <div id="slider"></div>
        <div class="rangBTN"><span>10</span><button type="button" @click="incrementSlider"><i class="icon-plus"></i></button></div>
      </div>
    </div>
  </div>
</template>

<script>
import noUiSlider from 'nouislider'
import wNumb from 'wnumb'
import 'nouislider/dist/nouislider.min.css'

export default {
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    question: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      sliderDefault: 5,
      sliderValue: null
    }
  },
  methods: {
    sliderInit: function () {
      // eslint-disable-next-line no-unused-vars
      var slider = document.getElementById('slider')

      noUiSlider.create(slider, {
        start: this.sliderValue,
        connect: 'lower',
        step: 1,
        tooltips: wNumb({ decimals: 0 }),
        range: {
          min: 1,
          max: 10
        },
        animate: true, // Enable smooth animation
        animationDuration: 300 // Set the duration of the animation in milliseconds (adjust as needed)
      })

      slider.noUiSlider.on('update', (values, handle) => {
        this.$emit('update:modelValue', parseInt(values[handle]))
      })
    },
    incrementSlider () {
      if (this.sliderValue < 10) {
        this.sliderValue += 1
        this.updateSlider()
      }
    },
    decrementSlider () {
      if (this.sliderValue > 1) {
        this.sliderValue -= 1
        this.updateSlider()
      }
    },
    updateSlider () {
      var slider = document.getElementById('slider')
      slider.noUiSlider.set(this.sliderValue)
    }
  },
  mounted () {
    this.sliderValue = this.modelValue || this.sliderDefault
    this.sliderInit()
  },
  unmounted () {
    // Remove the slider elements so they're not doubled up
    // NOTE: This approach could cause issues if there are multiple sliders (right now there's only 1)
    const slider = document.getElementById('slider')
    if (!slider) return
    const children = slider.getElementsByClassName('noUi-base')
    if (children && children[0]) {
      slider.removeChild(children[0])
    }
  }
}
</script>

<style lang="scss">
.form__item--range {
  width: 100%;
  padding-top: 50px !important;
  margin-bottom: -15px !important;
  display: flex;
}

#slider {
  display: inline-block;
    width: calc(100% - 120px);
    margin: 0 auto;
  &.noUi-horizontal {
    height: 1.5rem;

    .noUi-connects {
      border-radius: 0;
      height: 5px;
      top: 50%;
      transform: translateY(-50%);
    }

    .noUi-connect {
      background: $brand-secondary;
    }

    &.noUi-target {
      position: relative;
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      border: 0;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        z-index: 100;
        height: 5px;
        border: 1px solid $tt-green;
        border-top: 0;
        transform: translateY(-50%);
        pointer-events: none;
       }
    }

    .noUi-handle {
      width: 1.5rem;
      height: 1.5rem;
      background: $tt-green;
      box-shadow: none;
      border: 0;
      border-radius: 100%;
      transform: translate(50%, 0);
      right: 0;
      top: 50%;

      &::before,
      &::after {
        display: none;
      }
    }

    .noUi-tooltip {
      @include meta-text;
      bottom: auto;
      top: -35px;
      border: 0;
      border-radius: 0;
      background: transparent;
      padding: 0;
    }
  }
}
</style>
