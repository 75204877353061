<template>
  <div class="wrapper">
      <button class="button" @click="googleSignIn"><img :src="require('@/assets/google.svg')" alt="Google logo"/>{{actionText}} Google</button>
      <button class="button" @click="facebookSignIn"><img :src="require('@/assets/facebook.svg')" alt="Facebook logo"/>{{actionText}} Facebook</button>
<!--      <button class="button"><img :src="require('@/assets/apple.svg')" alt="Apple logo"/>{{actionText}} Apple</button>-->
    <p class="errorsub">
      {{ userErrorMessage }}
    </p>
  </div>

</template>

<script>

import { FacebookAuthProvider, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { mapActions, mapState } from 'vuex'

const googleAuthProvider = new GoogleAuthProvider()
const facebookAuthProvider = new FacebookAuthProvider()
// const appleAuthProvider = new OAuthProvider('apple.com')

export default {
  name: 'SocialAuth',
  props: {
    variant: {
      type: String,
      default: 'login'
    },
    onSuccess: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['firebaseAuth']),
    actionText () { return this?.variant === 'login' ? 'Sign in with' : 'Sign up with' }
  },
  data () {
    return {
      userErrorMessage: ''
    }
  },
  methods: {
    ...mapActions([
      'userAuthCheck',
      'initUserProfileData'
    ]),
    handleAccountCreationError (error) {
      this.allFieldsValid = false
      switch (error.code) {
        case 'auth/cancelled-popup-request':
          this.userErrorMessage = 'Too many requests. Please try again later.'
          this.allFieldsValid = false
          break
        case 'auth/popup-blocked':
          this.userErrorMessage = 'Operation blocked by browser.'
          this.allFieldsValid = false
          break
        default:
          break
      }

      return false
    },
    async googleSignIn () {
      try {
        const response = await signInWithPopup(this.firebaseAuth, googleAuthProvider)
        await this.userAuthCheck()
        await this.initUserProfileData(response)
        if (this.variant === 'register') {
          if (this.$gtm?.enabled()) {
            this.$gtm.trackEvent({
              event: 'Sign up',
              category: 'Sign up',
              action: 'click',
              label: 'google sign up',
              value: this.inputValue.email
            })
          }
        }
        this.onSuccess()
      } catch (error) {
        this.handleAccountCreationError(error)
        return false
      }
    },
    async facebookSignIn () {
      try {
        const response = await signInWithPopup(this.firebaseAuth, facebookAuthProvider)
        await this.userAuthCheck()
        await this.initUserProfileData(response)
        if (this.variant === 'register') {
          if (this.$gtm?.enabled()) {
            this.$gtm.trackEvent({
              event: 'Sign up',
              category: 'Sign up',
              action: 'click',
              label: 'facebook sign up',
              value: this.inputValue.email
            })
          }
        }
        this.onSuccess()
      } catch (error) {
        this.handleAccountCreationError(error)
        return false
      }
    }
    // async appleSignIn () {
    //   try {
    //     const response = await signInWithPopup(this.firebaseAuth, appleAuthProvider)
    //     await this.userAuthCheck()
    //     await this.initUserProfileData(response)
    // this.onSuccess()
    //   } catch (error) {
    //     this.handleAccountCreationError(error)
    //     return false
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    border-top: 1px solid #f4f5f9;
    padding-top: .5rem;
    display: flex;
    flex-direction: column;
    grid-column: span 12;
  }

  .button {
    width: 100%;
    border: none;
    outline: none;
    border-radius: 0.5rem;
    margin-top: .5rem;
    cursor: pointer;
    background: #f4f5f9;
    font-family: inherit;
    font-size: inherit;
    text-transform: none;
    display: flex;
    gap: 10px;
    margin-right: 0;
    &:hover {
      background: #e9e9e9;
    }
  }
</style>
