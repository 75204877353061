import { addDoc, collection, doc, updateDoc, getDoc, getDocs, where, query } from 'firebase/firestore'

const state = () => ({
  mySurveys: [],
  targetSurvey: null,
  mySelections: null,
  chosenFurnitureData: null
})

const getters = {
  mySurveys: state => state.mySurveys,
  inProgressSurveys: state => state.mySurveys.filter((survey) => {
    return survey.inProgress
  }),
  targetSurvey: state => state.targetSurvey,
  mySelections: state => state.mySelections,
  chosenFurnitureData: state => state.chosenFurnitureData
}

const mutations = {
  setMySurveys (state, val) {
    state.mySurveys = val
  },

  setTargetSurvey (state, val) {
    state.targetSurvey = val
  },

  setMySelections (state, val) {
    state.mySelections = val
  },

  setChosenFurnitureData (state, val) {
    state.chosenFurnitureData = val
  }
}

const actions = {
  // We can get firestore from rootState here instead of passing in
  async initFirestoreSurveysData ({ commit, state, rootState }) {
    if (state.targetSurvey !== null) {
      return
    }

    try {
      const initialData = {
        inProgress: true
      }
      if (rootState?.User?.currentUser?.uid) {
        const response = await addDoc(
          collection(rootState.firestore, 'users', rootState.User.currentUser.uid, 'surveysData'),
          initialData
        )

        commit('setTargetSurvey', { ...initialData, ...{ id: response.id } })
      } else {
        // localStorage.setItem('surveysTargetData', JSON.stringify({ ...initialData }))
        localStorage.setItem('survey-start-with-login', true)
      }
    } catch (err) {
      commit('setGlobalError', 'Sorry, there was an error saving your survey.', { root: true })
    }
  },
  async initFirestoreFromMiddelSurveysData ({ commit, state, rootState }) {
    if (state.targetSurvey !== null) {
      return
    }
    try {
      // const data = JSON.parse(localStorage.getItem('surveysTargetData'))
      const initialData = {}
      const response = await addDoc(
        collection(rootState.firestore, 'users', rootState.User.currentUser.uid, 'surveysData'),
        initialData
      )
      commit('setTargetSurvey', { ...initialData, ...{ id: response.id } })
    } catch (err) {
      commit('setGlobalError', 'Sorry, there was an error saving your survey.', { root: true })
    }
  },
  async updateFirestoreCurrentQuiz ({ commit, state, rootState }, payload) {
    // Add reference to responses so they can be mapped in airtable
    if (payload.responses) {
      payload.responses.userEmail = rootState?.User?.currentUser?.email
    }

    try {
      if (rootState?.User?.currentUser?.uid) {
        if (state.targetSurvey?.id) {
          await updateDoc(
            doc(rootState.firestore, 'users', rootState.User.currentUser.uid, 'surveysData', state.targetSurvey.id),
            payload
          )
        } else {
          const res = await addDoc(
            collection(rootState.firestore, 'users', rootState.User.currentUser.uid, 'surveysData'),
            payload
          )
          commit('setTargetSurvey', { ...payload, id: res.id })
        }
      }
      // TODO ask Tim&Lyndsey if we want to save answers to 2 first questions in localstorage
      // else {
      //   const dataInitialSurvey = JSON.parse(localStorage.getItem('user_details'))
      //   if (dataInitialSurvey !== null) {
      //     payload.responses.first_name = dataInitialSurvey?.first_name
      //     payload.responses.email = dataInitialSurvey?.email
      //     payload.responses.id = dataInitialSurvey?.id
      //   }
      //   // localStorage.setItem('surveysTargetData', JSON.stringify(payload))
      // }
    } catch (err) {
      commit('setGlobalError', 'Sorry, there was an error updating your survey.', { root: true })
      throw err
    }
  },

  /**
   * Get all of the currentUser's surveys (for the "boards") view
   */
  async getMySurveys ({ commit, rootState }) {
    const surveys = []
    if (rootState?.User?.currentUser?.uid) {
      const snapshot = await getDocs(
        collection(rootState.firestore, 'users', rootState.User.currentUser.uid, 'surveysData')
      )
      //
      snapshot.forEach((survey) => {
        const copy = survey.data()
        //
        copy.id = survey.id // Can be useful to have the doc ID so we're adding here
        surveys.push(copy)
      })
    }
    commit('setMySurveys', surveys)
    setTimeout(() => {
      commit('setMySurveys', surveys)
    }, 1000)
  },

  async getTargetSurvey ({ commit, state, dispatch, rootState }, payload) {
    await dispatch('userAuthCheck')
    if (!rootState?.User?.currentUser?.uid) return null

    const survey = await getDoc(
      doc(rootState.firestore, 'users', rootState.User.currentUser.uid, 'surveysData', payload.id)
    )

    if (!survey?.exists()) {
      return null
    }

    if (payload.setState === true) {
      commit('setTargetSurvey', { ...survey.data(), id: payload.id })
    }

    return survey.data()
  },

  /**
   * Gets the data for the target selection ID, and combines it with the
   * detailed furniture data associated with that selection
   * @param {String} selectionId - the ID of the selection that should be retrieved
   * @returns the selection object with detailed furniture data included
   */
  async getDetailedSelection ({ rootState }, selectionId) {
    const selection = await getDoc(doc(rootState.firestore, 'users', rootState.User.currentUser.uid, 'selections', selectionId))
    const detailedSelection = selection.data()

    if (detailedSelection?.id) {
      detailedSelection.id = selectionId
    }
    if (detailedSelection?.boards) {
      for (const i in detailedSelection.boards) {
        // for (const j in detailedSelection.boards[i].furniture) {
        //   const furnitureDoc = await getDoc(doc(rootState.firestore, 'furniture', detailedSelection.boards[i].furniture[j]))
        //   detailedSelection.boards[i].furniture[j] = furnitureDoc.data()
        // }
        const documentReferences = detailedSelection.boards[i].furniture.map(furnitureId =>
          doc(rootState.firestore, 'furniture', furnitureId)
        )
        const furnitureDocs = await Promise.all(documentReferences.map(async (docRef) => {
          const furnitureDoc = await getDoc(docRef)
          return furnitureDoc.data()
        }))
        detailedSelection.boards[i].furniture = furnitureDocs
      }
    }
    return detailedSelection
  },

  /**
   * Retrieves proper example selection based on user responses
   * @param {Object} responses - user survey responses
   * @returns the example selection ID
   */
  async findExampleSelectionName ({ rootState }, responses) {
    const name = `
      ${responses?.favorite_styles?.[0]?.toLowerCase()} ${
        responses?.favorite_styles?.[0] === 'moody'
          ? responses?.wood_tone?.toLowerCase() + ' '
          : ''
        }${responses?.type_of_furniture?.toLowerCase()}
      `.trim()

    return name
  },

  /**
   * Gets the data for the target selection ID, and combines it with the
   * detailed furniture data associated with that selection
   * @param {String} selectionId - the ID of the selection that should be retrieved
   * @returns the selection object with detailed furniture data included
   */
  async getDetailedExampleSelection ({ rootState }, selectionName) {
    const q = query(collection(rootState.firestore, 'example_selections'), where('name', '==', selectionName))

    const selection = (await getDocs(q))?.docs?.[0]
    const detailedSelection = selection.data()

    if (detailedSelection?.boards) {
      for (const i in detailedSelection.boards) {
        // for (const j in detailedSelection.boards[i].furniture) {
        //   const furnitureDoc = await getDoc(doc(rootState.firestore, 'furniture', detailedSelection.boards[i].furniture[j]))
        //   detailedSelection.boards[i].furniture[j] = furnitureDoc.data()
        // }
        const documentReferences = detailedSelection.boards[i].furniture.map(furnitureId =>
          doc(rootState.firestore, 'furniture', furnitureId)
        )
        const furnitureDocs = await Promise.all(documentReferences.map(async (docRef) => {
          const furnitureDoc = await getDoc(docRef)
          return furnitureDoc.data()
        }))
        detailedSelection.boards[i].furniture = furnitureDocs
      }
    }
    return detailedSelection
  },

  async getMySelections ({ commit, dispatch, rootState }, fullDetails) {
    const selections = []
    if (rootState.User?.currentUser?.uid) {
      const snapshot = await getDocs(
        collection(rootState.firestore, 'users', rootState.User.currentUser.uid, 'selections')
      )

      for (const i in snapshot.docs) {
        var copy = snapshot.docs[i].data()
        selections.push(copy)
      }
    }
    commit('setMySelections', selections)
  },

  async getSelections ({ rootState }, data) {
    const item = await getDoc(doc(rootState.firestore, 'furniture', data.furnitureId))
    localStorage.setItem('product_item_' + data.furnitureId, JSON.stringify(item.data()))
  },

  /**
   * Gets detailed data associated with each furniture ID chosen from the target selection
   */
  async getChosenFurnitureData ({ commit, state, rootState }, selectionId) {
    const selection = await getDoc(doc(rootState.firestore, 'users', rootState.User.currentUser.uid, 'selections', selectionId))
    const selectionData = selection.data()
    const furnitureItems = selectionData.chosen
    const furnitureData = []
    const furniture = []
    var vendors = 0
    var orderStripeData = ''
    if (selectionData?.stripeOrderData) {
      orderStripeData = JSON.parse(selectionData.stripeOrderData, true)
    }

    for (const i in furnitureItems) {
      var copy = {}
      // const key = 'product_item_' + furnitureItems[i].id
      // var productItem = localStorage.getItem(key)
      // if (productItem !== null) {
      //   copy = JSON.parse(localStorage.getItem('product_item_' + furnitureItems[i].id))
      // } else {
      const item = await getDoc(doc(rootState.firestore, 'furniture', furnitureItems[i].id))
      copy = item.data()
      // }

      // Make collection vendors
      if (orderStripeData?.whiteGloveStatus) {
        if (orderStripeData && orderStripeData?.whiteGloveStatus[copy?.recordId] === 'standard') {
          vendors = vendors + (copy?.itemPrice * copy?.quantity)
        }
      }
      // We'll add the full chosen furniture data, including pos, just so its easier to remove with FieldValue.arrayRemove
      copy.chosenData = furnitureItems[i]
      furnitureData.push(copy)
    }

    for (const items in furnitureData) {
      if (vendors < 3000) {
        furnitureData[items].shipping.standardShippingPrice = 199
      } else if (vendors > 3000 && vendors < 5000) {
        furnitureData[items].shipping.standardShippingPrice = 349
      } else if (vendors > 5000 && vendors < 7000) {
        furnitureData[items].shipping.standardShippingPrice = 459
      } else if (vendors > 7000 && vendors < 10000) {
        furnitureData[items].shipping.standardShippingPrice = 549
      } else {
        furnitureData[items].shipping.standardShippingPrice = 649
      }

      furniture.push(furnitureData[items])
    }
    commit('setChosenFurnitureData', furniture)
  },

  async getSelection ({ rootState }, selectionId) {
    const selectionDoc = await getDoc(doc(rootState.firestore, 'users', rootState.User.currentUser.uid, 'selections', selectionId))
    return selectionDoc.data()
  },

  async updateTargetSelection ({ rootState }, payload) {
    updateDoc(
      doc(rootState.firestore, 'users', rootState.User.currentUser.uid, 'selections', payload.target),
      payload.data
    )
  },

  async getOrder ({ rootState }, orderId) {
    const orderDoc = await getDoc(doc(rootState.firestore, 'users', rootState.User.currentUser.uid, 'orders', orderId))
    return orderDoc.data()
  },
  async getNewLineItem ({ rootState }, payload) {
    const lineItemDoc = await getDoc(
      doc(
        rootState.firestore,
        'users',
        rootState.User.currentUser.uid,
        'lineItems',
        payload.lineItemId
      )
    )
    return lineItemDoc.data()
  },
  async getLineItem ({ rootState }, payload) {
    const lineItemDoc = await getDoc(
      doc(
        rootState.firestore,
        'users',
        rootState.User.currentUser.uid,
        'orders',
        payload.orderId,
        'lineItems',
        payload.lineItemId
      )
    )
    return lineItemDoc.data()
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
