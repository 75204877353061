<template>
  <div class="form__note">
    <div class="form__content">
      <h2 v-if="title" class="form__note__heading">{{ title }}</h2>
      <div class="text">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
