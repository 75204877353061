<template>
  <transition name="fade" mode="out-in">
    <form class="form">
      <div class="grid__inner">
        <fieldset class='form__question new_form_style'>
          <div class="form__content">
            <div class="form__item form__item--text inputColumns form__item--half">
              <label>First Name</label>
              <input
                type="text"
                autocomplete="given-name"
                placeholder="First name"
                v-model="inputValue['first_name']"
                required
              >
            </div>
            <div class="form__item form__item--text inputColumns form__item--half">
              <label>Email Address</label>
              <input
                type="email"
                autocomplete="email"
                placeholder="Email address"
                v-model="inputValue['email']"
                required
                @blur="validateInput"
              >
            </div>
            <div v-if="userErrorMessage" class="form-validation warning">
              <span class="closeicon"><i class="icon-close"></i></span>
              <div>
              <h5>There was a problem with your submission.</h5>
              <p class="errorsub"><span></span>
                {{ userErrorMessage }}
              </p>
            </div>
            </div>
          </div>
        </fieldset>
        <div class="signinOption">
          <div class="signBOx">
            <p>Already have an account? <a :href="undefined" @click="showModalSignup">Sign in</a></p>
          </div>
          </div>

          <div class="imageGrid">
            <div class="imagegirdBox">
            <div><img src="@/assets/theme/images/gallery1.jpg" /></div>
            <div><img src="@/assets/theme/images/gallery2.jpg" /></div>
            <div><img src="@/assets/theme/images/gallery3.jpg" /></div>
            <div><img src="@/assets/theme/images/gallery4.jpg" /></div>
            <div><img src="@/assets/theme/images/gallery5.jpg" /></div>
            <div><img src="@/assets/theme/images/gallery6.jpg" /></div>
          </div>
          </div>
      </div>
    </form>
  </transition>
  <Loader :visible="loading" fixed />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Loader from '@/components/Loader'
import { httpsCallable } from 'firebase/functions'
// import { httpsCallable } from 'firebase/functions'
// import { validateEmailString } from '@/assets/scripts/helpers'
// import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'

// import { updateProfile } from 'firebase/auth'

export default {
  name: 'BasicRegistration',
  components: {
    Loader
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: false,
      inputValue: {},
      userErrorMessage: '',
      allFieldsValid: false,
      showPassword: false,
      signedIn: false
    }
  },

  computed: {
    ...mapState(['fbFunctions', 'firebaseAuth', 'firestore']),
    ...mapGetters([
      'currentUser'
    ])
  },

  methods: {
    ...mapMutations([
      'setModalSignup',
      'setGlobalError'
    ]),
    ...mapActions([
      'userAuthCheck',
      'initUserProfileData'
    ]),
    showModalSignup () {
      this.setModalSignup(true)
    },
    closeModalSighup () {
      this.setModalSignup(false)
    },
    async checkIfUserExistsForEmail (email) {
      return await httpsCallable(this.fbFunctions, 'newCheckIfEmailExists')({ email })
    },
    async validateInput () {
      const res = await this.checkIfUserExistsForEmail(this.inputValue.email)
      if (res.data.exists) {
        this.userErrorMessage = 'Account already exists for this email address, please sign in!'
      }
    }
  },

  async mounted () {
    this.inputValue = this.modelValue || ''
  }

}

</script>

<style lang="scss" scoped>
.quiz__container--full {
  display: grid;
  grid-column: span 12;

  p {
    margin-bottom: calc(var(--leading) * .667);
    text-align: center;
    font-size: 0.8rem;
  }

  a.underline {
    text-decoration: underline;
  }
}
.show-password__reveal--text {
  color: $tt-off-white;
}
.social-btn{
  display:flex;
  align-items: center;
  justify-content: center;
}

#appleid-signin svg text {
  display:none !important;
}

.social-btn > div {
    margin: 5px;
    width:auto;
}

.social-auth img {
  width:45px;
}

div#appleid-signin {
    height: 48px;
    width: 48px;
    border-radius: 50px;
    overflow: hidden;
    cursor: pointer;
}

.social-work {
  display:block;
}
.social-work .quiz__navigation {
      position: relative !important;
}

.social-work .social-btn {
  margin-top:2rem;
}
.g-social-btn{
  width:45px;
  height:45px;
  padding:.5rem;
}
.g-social-btn img{ width: 20px;}
.g-btn-wrapper {
  margin-top:2rem;
}
button.login-button {
  background-color: white;
  height: 32px;
  width: 200px;
  border-radius: 5px;
  border: 0;
  font-size: 12px;
  font-weight: 500;
  margin: 5px;
}
button.login-button svg{
  height: 15px;
  margin-top: -3px;
  margin-right: 3px;
}

.social-btn{
  display:flex;
  align-items: center;
  justify-content: center;
}

#appleid-signin svg text {
  display:none !important;
}

.social-btn > div {
    margin: 5px;
    width:auto;
}
.social-auth img {
  width:45px;
}
div#appleid-signin {
    height: 48px;
    width: 48px;
    border-radius: 50px;
    overflow: hidden;
    cursor: pointer;
}
</style>
