<template>
  <div
    class="form__style-radio-3 form__style-radio--horizontal sofa_style"
    :class="{ 'form__style-radio--horizontal--two': options.length == 2, 'form__style-radio--horizontal--narrow': narrow }"
  >
    <div
      v-for="(option, index) in options"
      :key="`option-${index}`"
      class="form__style-radio--horizontal__item"
      :class="{ 'form__style-radio--horizontal__item--two': options.length == 2, 'place-checked': isChecked(option) }"
    >
      <div class="form__item form__item--radio">
        <label :for="`option-${index}`">
            <img :src="require(`@/assets/images/${option.green_img}`)" class="green_img">
            <img :src="require(`@/assets/images/${option.image}`)" class="normal_img">
        </label>
        <div class="radio-container" :class="{ 'has-label': !!option.label }">
          <input
            :value="option.value"
            type="radio"
            :name="`option-${index}`"
            :id="`option-${index}`"
            @input="updateValue"
            v-model="radioValue"
            @change="handleRadioChange"
          >
          <label v-if="option.label" :for="`option-${index}`" class="text-label">
            {{ option.label }}
          </label>
        </div>
      </div>
    </div>
    <ValidationWarning :valid="valid" />
  </div>
</template>

<script>

export default {
  name: 'StyleRadio',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    autoProceed: {
      type: Boolean,
      default: true
    },
    narrow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: true,
      radioValue: ''
    }
  },
  computed: {
    isChecked () {
      return (option) => this.radioValue === option.value
    }
  },
  mounted () {
    this.radioValue = this.modelValue || ''
  },
  methods: {
    updateValue (event) {
      this.$emit('update:modelValue', event.target.value)
    },
    handleRadioChange (event) {
      this.updateValue(event)

      // Check if autoProceed is true
      if (this.autoProceed) {
        // Emit a custom event to notify the parent component
        this.$emit('radioClicked', this.radioValue)
      }
    },
    validate () {
      this.valid = !(this.required && this.modelValue.length < 1)
      return this.valid
    }
  },
  watch: {
    modelValue: {
      handler () {
        this.validate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form__item {
  &--radio {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
    height: 100%;
  }
}

.form__style-radio {
  &--horizontal__item {
      padding: 2rem 2rem 1rem 2rem;
  }
}
</style>
