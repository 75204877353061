<template>
  <div class="form__specs-options">
    <div class="form__content">
      <div class="specs__options">
        <div class="specs__options__item specs__options__item--1 spaceOPtion">
          <header class="specs__options__item__header">
            <div class="specs__options__item__header__number"><span>1</span></div>
            <h2 class="specs__options__item__header__heading">
              On-site service
            </h2>
          </header>
          <div class="text heightChange">
            <p>Too busy to take photos? Don’t trust your measuring skills? If you’re in the NYC area, we’ll send a toTree specialist to your home to handle it. On us.</p>
          </div>
          <button
            class="tertiary block btn-Border"
            :class="{ selected: modelValue === 'on-site-service' }"
            @click.prevent="$emit('update:modelValue', 'on-site-service')"
            :disabled="ossUnavailable"
          >
            Select
          </button>
          <p v-if="ossUnavailable" class="oss-unavailable">
            We&rsquo;re sorry, On-Site Service is not available for your zip code.
          </p>
        </div>

        <div class="specs__options__item specs__options__item--2 spaceOPtion">
          <header class="specs__options__item__header">
            <div class="specs__options__item__header__number"><span>2</span></div>
            <h2 class="specs__options__item__header__heading">
              DIY (do it yourself)
            </h2>
          </header>
          <div class="text heightChange">
            <p>If you&rsquo;re game to do it yourself, simply follow the instructions below. To lend a helping hand, we&rsquo;re happy to jump on a call/FaceTime and walk you through it. All you&rsquo;ll need are:</p>
            <ul>
              <li>iPhone/Android phone</li>
              <li>A tape measure OR a measuring app for your phone</li>
            </ul>
          </div>
          <button
            class="tertiary block btn-Border"
            :class="{ selected: modelValue === 'diy' }"
            @click.prevent="$emit('update:modelValue', 'diy')"
          >
            Select
          </button>
        </div>
        <ValidationWarning :valid="valid" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { inOssZipCodeRange } from '@/assets/scripts/helpers'
export default {
  props: {
    required: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: String,
      default: ''
    },
    zipCode: {
      type: String,
      default: ''
    },
    autoProceed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: true
    }
  },
  computed: {
    ...mapGetters(['targetSurvey']),

    /**
     * Checks the zip code passed from the projectAddress
     */
    ossUnavailable () {
      return !inOssZipCodeRange(this.zipCode)
    }
  },

  methods: {
    validate () {
      this.valid = !(this.required && !this.modelValue)
      return this.valid
    }
  },
  watch: {
    modelValue: {
      handler () {
        this.validate()
      }
    }
  }
}
</script>
