<template>
  <div class="form__question" :class="{ loading: clipboardLoader }">
    <div class="form__content">
      <div class="payment__section">
        <template v-if="!alreadyPaid">
          <div class="payment__what-you-get">
            <img
              src="@/assets/images/service-fee-stamp.png"
              :alt="`$${selectionServiceFee} flat rate`"
              class="payment__what-you-get__stamp"
            >
            <p class="payment__copy">
              We know it might seem unusual to break out your credit card before you've bought anything – or even seen your options. But look what your <strong>Customization Fee</strong> gets you!
              <br><br>
              First and foremost, <strong>a pair of Style Boards: your personalized furniture options based on your specific needs, tastes and budget.</strong> They're yours to keep. Show them to your friends, get opinions, even use them as inspiration or guidance for the rest of your home.
            </p>
          </div>
        </template>
        <div class="payment__image">
          <img src="@/assets/images/payment-hero.jpg" alt="Comfort, Confidence, Productivity, Relaxation, Social Life, Love Life">
        </div>

        <p v-if="alreadyPaid" class="payment__copy payment__copy--thanks">
          Thanks for your previous payment! Remember, your initial selection fee of ${{ selectionServiceFee }} is good for up to four rooms at no extra charge.
        </p>

        <template v-if="!alreadyPaid">
          <p class="payment__copy">
            But it's not just Style Boards! That Customization Fee also gets you:
          </p>
          <ul class="payment__copy payment__copy--list">
            <li>Your own dedicated Living Space Specialist</li>
            <li>Assurance that your furniture will fit in your space</li>
            <li>Well-known brands that offer quality and sustainable furniture</li>
            <li>A seamless user experience with unique features including our 'Mix and Match' selection tool</li>
            <li>Assistance with any returns/exchanges</li>
            <li>Up to six months to make any purchase</li>
          </ul>
        </template>

        <h3 class="payment__heading payment__heading--section"></h3>
        <div class="form__item payment__copy">
          <p>Why do we ask? We want to make sure we get the best selections possible, and sometimes location is a factor.</p>
        </div>
        <div class="form__item form__item--text">
          <input
            type="text"
            pattern="\d*"
            placeholder="Zip Code"
            v-model="shippingAddressZip"
            class="hide-arrows"
          >
        </div>

        <template v-if="!alreadyPaid">
          <h3 class="payment__heading payment__heading--section">Payment Information</h3>
          <div class="form__stripe">
            <div>
              <div id="stripe-payment-element" @focus="isAddressDataValid">
                <div id="stripe-element-error-message"></div>
              </div>
            </div>
          </div>

          <!-- Coupon input -->
          <div class="form__item coupon-input-container">
            <h3 class="payment__heading">Have a coupon?</h3>
            <div
              class="form__item form__item--text coupon-input"
              :class="{ valid: !loading && discountApplied }"
            >
              <input
                v-model="couponCode"
                type="text"
                placeholder="Coupon Code"
                @blur="updatedCouponCode"
              >
            </div>
            <div v-if="!loading && discountApplied" class="form__item">
              <p class="discount-message">Applied discount of ${{ discountApplied }} to your purchase!</p>
            </div>
          </div>
        </template>
      </div>

      <div v-if:="errorMessages" class="warning">
        {{this.errorMessages}}
      </div>
    </div>
    <Loader :visible="loading" fixed blocking />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { getFunctions, httpsCallable } from 'firebase/functions'
// See https://stackoverflow.com/questions/62358488/importing-stripe-js-as-an-es-module-into-vue
import { loadStripe } from '@stripe/stripe-js/pure'
import Loader from '@/components/Loader'
import { getStateFromZipUS } from '@/assets/scripts/helpers'

const cloudFunction = getFunctions()
export default {
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Loader
  },

  async created () {
    const result = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, {
      betas: ['process_order_beta_1'],
      apiVersion: '2022-08-01; orders_beta=v4'
    })
    this.stripeJS_elements = result.elements
    this.stripeJS_processOrder = result.processOrder
    this.stripeJS_confirmPayment = result.confirmPayment
  },

  data () {
    return {
      loading: false,
      shippingAddressZip: null,
      checkPhone: {
        isValid: false
      },
      stripeResponses: {},
      cloudFunction: null,
      httpsCall: null,
      errorMessages: '',
      associatedStripeCustomer: null,
      stripeJS_elements: {},
      stripeJS_processOrder: {},
      stripeJS_confirmPayment: {},
      stripeClientElements: null,
      alreadyPaid: false,
      paymentIntent: null,
      orderData: null,
      couponCode: null,
      discountApplied: null
    }
  },

  computed: {
    ...mapState([
      'showDebug',
      'firebaseAuth',
      'firestore',
      'stripeClient',
      'usStates',
      'selectionServiceFee',
      'selectionDiscountThreshold',
      'clipboardLoader'
    ]),
    ...mapGetters([
      'currentUser',
      'userProfile',
      'targetSurvey'
    ])
  },

  async mounted () {
    const mountedStartTime = new Date().getTime()
    this.setClipboardLoader(true)
    try {
      const res = await httpsCallable(cloudFunction, 'sendStripeGetCustomer')()
      this.associatedStripeCustomer = res.data
    } catch (err) {
      this.setGlobalError('Sorry, there was an error retrieving your customer information.')
    }
    this.shippingAddressZip = this.targetSurvey?.projectAddress?.zip || this.userProfile.shipping_zip || ''
    const existingOrderId = this.userProfile?.selectionFeeOrderId
    if (existingOrderId) {
      // If the user has already created an order, we'll retrieve it rather than creating another one.
      if (this.userProfile.selectionFeeOrderStatus === 'open') {
        const result = await httpsCallable(cloudFunction, 'sendStripeRetrieveOrder')(existingOrderId)
        this.orderData = result.data
        this.createStripeElements()
      }
      if (this.userProfile.selectionFeeOrderStatus === 'complete') {
        this.alreadyPaid = true
      }
    } else {
      // If the user doesn't have a Stripe customer ID, that needs to be set up first.
      // This should happen when the user profile is created, but this is here as a backup.
      if (!this.userProfile?.stripeCustomerId) {
        await httpsCallable(cloudFunction, 'sendStripeCreateNewCustomer')()
      } else {

      }
      // Once the user is created, we can set up payment intent.
      await this.createSelectionFeePaymentOrder()
    }
    // We want to show the clipboard loader for a minimum of 2500ms
    const mountedEndTime = new Date().getTime()
    const timeDiff = mountedEndTime - mountedStartTime
    let delay = 0
    const minTimeShown = 2500
    if (timeDiff < minTimeShown) {
      delay = minTimeShown - timeDiff
    }
    setTimeout(() => {
      this.setClipboardLoader(false)
    }, delay)
  },
  methods: {
    ...mapMutations([
      'setCurrentUser',
      'setUserProfile',
      'setTargetSurvey',
      'setGlobalError',
      'setClipboardLoader'
    ]),
    ...mapActions(['updateFirestoreUserProfile', 'updateFirestoreCurrentQuiz']),

    validate () {
      // Strip out extra characters
      this.shippingAddressZip = this.shippingAddressZip.replace(/\D/g, '')

      // Go ahead and get the state to validate that it's a real zip
      const stateFromZip = getStateFromZipUS(this.shippingAddressZip)

      if (this.required && (!this.shippingAddressZip || !stateFromZip || stateFromZip === 'none')) {
        this.errorMessages = 'Please enter a valid shipping zip code.'
        return false
      }
      return true
    },

    async updatedCouponCode () {
      this.errorMessages = ''
      if (!this.couponCode) return
      this.loading = true

      try {
        const response = await httpsCallable(cloudFunction, 'applyCouponCode')({
          code: this.couponCode,
          order: this.orderData.id
        })

        if (response.data === 'invalid-code') {
          this.errorMessages = 'Sorry this Code is not Valid.'
        } else {
          this.discountApplied = response.data?.total_details?.amount_discount / 100
        }
      } catch (err) {
        this.setGlobalError('Sorry, there was an error applying your coupon.')
      }
      this.loading = false
    },

    // TODO: Currently inactive
    async atStripeUpdateCustomer () {
      // validate input values
      if (!this.isAddressDataValid()) {
        return false
      }

      this.errorMessages = null

      const stripePayload = {
        name: this.userProfile.shipping_name || this.userProfile.first_name,
        email: this.userProfile.email_address,
        // TODO
        shipping: {
          address: {
            postal_code: '',
            state: ''
          }
        }
      }
      const stripeCloudFunction = httpsCallable(cloudFunction, 'sendStripeUpdateCustomer')
      await stripeCloudFunction(stripePayload).then((result) => {
        return result
      })

      return stripePayload
    },

    async createSelectionFeePaymentOrder () {
      try {
        const result = await httpsCallable(cloudFunction, 'createSelectionFeeOrder')({
          surveyId: this.targetSurvey.id
        })

        this.orderData = result.data
        this.createStripeElements()
      } catch (err) {
        this.setGlobalError('Sorry, an error occurred when creating your order.')
      }
    },

    createStripeElements () {
      const appearance = {
        theme: 'flat',
        labels: 'above',
        variables: {
          colorText: '#505050',
          colorPrimary: '#576b5c',
          colorBackground: '#f3f3f3',
          colorDanger: '#938839',
          spacingUnit: '.4em',
          borderRadius: '0',
          fontFamily: 'Andale Mono, Courier, monospace',
          spacingTab: '1px',
          colorTextPlaceholder: '#c1c8b9'
        },
        rules: {
          '.Input': {
            borderBottom: '1px solid #576b5c',
            padding: '.3em',
            fontSize: '.75em'
          },
          '.Tab': {
            textTransform: 'uppercase',
            padding: '.6em',
            fontSize: '.75em',
            fontWeight: 'normal'
          },
          '.Label': {
            textTransform: 'uppercase',
            padding: '.3em .3em .1em',
            fontSize: '.75em',
            color: '#576b5c',
            fontWeight: 'normal'
          }
        }
      }

      // use appearence: {} to modify the look and feel

      const payOptionsPayload = {
        clientSecret: this.orderData.client_secret,
        appearance
      }
      this.stripeClientElements = this.stripeJS_elements(payOptionsPayload)

      const paymentsElements = this.stripeClientElements.create('payment')
      paymentsElements.mount('#stripe-payment-element')
    },

    /**
     * Update the project address for the current survey
     */
    async updateProjectZip () {
      const stateFromZip = getStateFromZipUS(this.shippingAddressZip)

      // If the user hasn't already paid, we need to validate the address before proceeding...
      if (!this.alreadyPaid && !this.isAddressDataValid()) {
        throw new Error('Invalid address!')
      }

      const projectZipState = {
        projectAddress: {
          state: stateFromZip,
          zip: this.shippingAddressZip
        }
      }
      if (!this.userProfile.shipping_state || !this.userProfile.shipping_zip) {
        await this.updateFirestoreUserProfile({
          shipping_state: stateFromZip,
          shipping_zip: this.shippingAddressZip
        })
      }
      await this.updateFirestoreCurrentQuiz(projectZipState)
      // Update the survey in state with the project zip / state
      this.setTargetSurvey({
        ...this.targetSurvey,
        ...projectZipState
      })
    },

    /**
     * This initiates the single purchase of the Interior Design Services
     * Note that this is being controlled by the grandparent Quiz.vue
     * under the checkSpecialCases() subroutine.
     */
    async atStripeSubmitConfirmPayment (e) { // REMOTE STEP 2 (SUBMIT)
      const elements = this.stripeClientElements
      let confirmPaymentResponse = {}

      // validate input values
      if (!this.isAddressDataValid()) {
        confirmPaymentResponse = {
          error: {
            type: 'billing_info_required',
            code: 'billing_info_validation',
            message: 'Please check your billing information & shipping zip code.'
          }
        }
        return false
      }

      // If the user doesn't already have an address associated with their Stripe customer,
      // We'll save it here. Note that we only have zip / state (inferred) at this point
      // if (!this.associatedStripeCustomer.shipping) {
      //   confirmPaymentResponse = await this.atStripeUpdateCustomer() // REMOTE STEP 3
      // }

      //
      // if (!confirmPaymentResponse) {
      //   return false
      // }

      confirmPaymentResponse = await this.stripeJS_processOrder({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${process.env.VUE_APP_ROOT_URL}/stripeCheckout`
        }
      })

      await this.uponStripeConfirmPayment(confirmPaymentResponse)

      return confirmPaymentResponse
    },

    async uponStripeConfirmPayment (payload) {
      if (payload.error) {
        const error = payload.error ? payload.error : null

        switch (error.type) { // Handles card level errors returned from stripe
          case 'card_error': {
            this.errorMessages = error.message

            return payload
            // break
          }

          case 'validation_error': {
            this.errorMessages = 'Validation Error, ' + error.message

            return payload
            // break
          }

          default:

            this.errorMessages = error.message + ' No charges were made.'

            return payload
        }
      } else if (payload.paymentIntent) { // Charge was successful!
        this.errorMessages = null
        this.alreadyPaid = true

        return payload
      } else { // should not get here
        return payload
      }
    },

    isAddressDataValid () {
      this.errorMessages = ''

      if (!this.shippingAddressZip) {
        this.errorMessages = 'Please enter the Zip Code you will be shipping to.'

        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.form__question {
  &.loading {
    height: 0;
    overflow: hidden;
    opacity: 0;

    @include breakpoint($s) {
      height: calc(100vh - 380px);
    }
  }
}
.coupon-input-container {
  margin: calc(var(--gutter) * 2) 0 0;

  .discount-message {
    @include meta-text;
  }
}
.coupon-input {
  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto 66%;

  &.valid {
    background-image: url('../../../assets/images/check.svg');
  }
  &.invalid {
    background-image: url('../../../assets/images/close.svg');
  }
}

.warning {
  grid-column: span 12;
}
.payment__section {
  display: grid;
  grid-column: span 12;
}
.payment__copy {
  font-size: 1.25rem;
  line-height: 1.5rem;
  grid-column: span 12;

  &--list {
    list-style: disc;
    padding-left: var(--gutter);
    margin: var(--leading-h2) 0;
  }

  &--center {
    text-align: center;
  }

  strong {
    font-weight: 700;
  }
}
.payment__heading {
  font-family: 'GT America Expanded';
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 300;
  color: $tt-green;
  grid-column: span 12;
  margin-bottom: var(--leading-h2);

  &--section {
    margin-top: var(--leading-h2);
  }
}

.payment__image {
  grid-column: span 12;
  width: 100%;
  margin: var(--leading-h2) 0 calc(var(--leading-h2) * 2);
}

.payment__what-you-get {
  display: grid;
  grid-column: span 12;
  grid-gap: 0 var(--gutter);
  grid-template-columns: repeat(12, 1fr);
  margin: var(--gutter) 0;

  &__stamp {
    grid-column: span 3;
  }
  .payment__copy {
    grid-column: span 9;
  }
}

.form__stripe {
  width: 100%;
  grid-column: span 12;
}
</style>
