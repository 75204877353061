
<template>
  <fieldset class='form__question'>
    <div class="form__content">
      <legend class="quiz__subheading">{{ question.question }}</legend>
      <div
        v-for="(inputField, index) in question.inputFields"
        :id="question.questionsRefId"
        :key="index"
        :class="`form__item form__item--text inputColumns ${(inputField.trait && inputField.trait === 'full-width') ? '' : 'form__item--half'}`"
      >
        <!-- NOTE that :name :v-model @input="updateValue" is what is passing data to the grandparent via the updateValue function in the method-->
        <input
          :type="inputField.type"
          :placeholder="inputField.text"
          :key="`${question.questionsRefId}--${index}`"
          v-model="inputValue[inputField.value]"
          @input="updateValue"
        >
      </div>
    </div>
  </fieldset>
  <div class="quiz__container--full">
    <div class="quiz_container_half">
      <p>Already have an <br />account?</p>
      <button>Sign In</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    question: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      inputValue: {}
    }
  },
  mounted () {
    this.inputValue = this.modelValue || {}
  },
  methods: {
    updateValue () {
      this.$emit('update:modelValue', this.inputValue)
    }
  },
  watch: {
    modelValue: {
      handler (val) {
        if (val) {
          this.inputValue = val || {}
        }
      }
    }
  }
}
</script>
