/**
 * This centralized JS file contains data about all questions that may be asked during the quiz.
 * It helps the already sprawling Quiz.vue from getting too bloated with extra data
 */
/* eslint-disable camelcase */

/*
   ______   _______  _______ _________ _______  _______
  (  ___ \ (  ___  )(  ____ \\__   __/(  ____ \(  ____ \
  | (   ) )| (   ) || (    \/   ) (   | (    \/| (    \/
  | (__/ / | (___) || (_____    | |   | |      | (_____
  |  __ (  |  ___  |(_____  )   | |   | |      (_____  )
  | (  \ \ | (   ) |      ) |   | |   | |            ) |
  | )___) )| )   ( |/\____) |___) (___| (____/\/\____) |
  |/ \___/ |/     \|\_______)\_______/(_______/\_______)
*/
// Step - What kind of place?
export const what_kind_of_place = [
  {
    value: 'House',
    green_img: 'place-house-color.jpg',
    image: 'place-house.jpg',
    label: 'House'
  },
  {
    value: 'Apartment/condo/co-op',
    green_img: 'place-condo-color.jpg',
    image: 'place-condo.jpg',
    label: 'Apartment/condo/co-op'
  },
  {
    value: 'Other',
    green_img: 'place-other-color.jpg',
    image: 'place-other.jpg',
    label: 'Other'
  }
]

// Textarea
export const describe_other = {
  question: 'Please tell us more about the place you are furnishing',
  questionsRefId: 'describeOther-0'
}

// Radio
export const upgrading_or_from_scratch = {
  questionsRefId: 'existing-2',
  question: 'Are you looking to upgrade your existing furniture or are you starting from scratch (an empty space)?',
  inputFields: [
    {
      text: 'Upgrading the old stuff',
      value: 'upgrading'
    },
    {
      text: 'I’ve got a blank canvas',
      value: 'blank-canvas'
    }
  ]
}

// Radio
export const need_help = {
  question: 'Furnishing an empty space can be daunting. Need some help with the layout?',
  questionsRefId: 'needHelp-2',
  inputFields: [
    {
      value: true,
      text: 'Sure, I could use a little layout expertise'
    },
    {
      value: false,
      text: 'No thanks, I’ve got it covered'
    }
  ]
}

// Radio
export const type_of_furniture = {
  questionsRefId: 'whatfor-2',
  question: 'While we can help furnish as many rooms as you need, we break them down one at a time. Where would you like to start?',
  inputFields: [
    {
      value: 'living-room',
      text: 'Living room'
    },
    {
      value: 'bedroom',
      text: 'Bedroom'
    },
    {
      value: 'dining-room',
      text: 'Dining room'
    }
    // KEEP: OFFICE
    // {
    //   value: 'office',
    //   text: 'Office'
    // }
  ]
}

// Textfield
export const project_name = {
  question: 'Now it’s time to give your survey a name. (We\'ll refer to your project with this name)',
  placeholder: 'i.e. Living Room Upgrade'
}

// Checkbox
export const replace_living_room = {
  question: 'Looking for specific items? Select ‘em here.',
  inputFields: [
    {
      value: 'nothing-specific',
      text: 'Nothing specific - whatever you think!'
    },
    {
      value: 'sofa',
      text: 'Sofa'
    },
    {
      value: 'chair',
      text: 'Chair'
    },
    {
      value: 'ottoman',
      text: 'Ottoman'
    },
    {
      value: 'sideboard/media-console',
      text: 'Sideboard / media console'
    },
    {
      value: 'coffee-table',
      text: 'Coffee table'
    },
    {
      value: 'side-table',
      text: 'Side table'
    },
    {
      value: 'rug',
      text: 'Rug'
    }
    // KEEP: OFFICE
    // {
    //   value: 'office-furniture',
    //   text: 'Office furniture'
    // },
  ]
}

// Checkbox
export const replace_bedroom = {
  question: 'Sometimes a few specific items are all you need. If that’s the case, just check the appropriate boxes. (And if you’re not sure, take a look at our <a href="/glossary" target="_blank">glossary</a>!)',
  inputFields: [
    {
      value: 'nothing-specific',
      text: 'Nothing specific - whatever you think!'
    },
    {
      value: 'bed',
      text: 'Bed'
    },
    {
      value: 'nightstands',
      text: 'Nightstands'
    },
    {
      value: 'dresser',
      text: 'Dresser'
    },
    {
      value: 'rug',
      text: 'Rug'
    },
    {
      value: 'chair',
      text: 'Chair'
    },
    {
      value: 'bench',
      text: 'Bench'
    }
  ]
}

// Checkbox
export const replace_dining_room = {
  question: 'Sometimes a few specific items are all you need. If that’s the case, just check the appropriate boxes. (And if you’re not sure, take a look at our <a href="/glossary" target="_blank">glossary</a>!)',
  inputFields: [
    {
      value: 'nothing-specific',
      text: 'Nothing specific - whatever you think!'
    },
    {
      value: 'dining-table',
      text: 'Dining table'
    },
    {
      value: 'dining-chairs',
      text: 'Dining chairs'
    },
    {
      value: 'stools',
      text: 'Stools'
    },
    {
      value: 'rug',
      text: 'Rug'
    }
  ]
}

// Checkbox
export const replace_office = {
  question: 'Sometimes a few specific items are all you need. If that’s the case, just check the appropriate boxes. (And if you’re not sure, take a look at our <a href="/glossary" target="_blank">glossary</a>!)',
  inputFields: [
    {
      value: 'nothing-specific',
      text: 'Nothing specific - whatever you think!'
    },
    {
      value: 'standing-desk',
      text: 'Standing desk'
    },
    {
      value: 'desk',
      text: 'Desk'
    },
    {
      value: 'desk-chair',
      text: 'Desk chair'
    },
    {
      value: 'rug',
      text: 'Rug'
    }
  ]
}

// Radio
export const your_timeline = {
  question: 'What’s your timeline?',
  inputFields: [
    {
      text: 'I needed my furniture yesterday',
      value: 1
    },
    {
      text: 'I\'m in no real rush',
      value: 0
    }
  ]
}

// Textarea
export const timeline_notes = {
  question: 'Anything else you want to mention about your timeline?'
}

// Radio with Imagery
export const wood_tone_options = [
  {
    value: 'light',
    green_img: 'wood1.jpg',
    image: 'wood1.jpg'
  },
  {
    value: 'dark',
    green_img: 'wood2.jpg',
    image: 'wood2.jpg'
  }
]

/*
   _     _       _              ______
  | |   (_)     (_)             | ___ \
  | |    ___   ___ _ __   __ _  | |_/ /___   ___  _ __ ___
  | |   | \ \ / / | '_ \ / _` | |    // _ \ / _ \| '_ ` _ \
  | |___| |\ V /| | | | | (_| | | |\ \ (_) | (_) | | | | | |
  \_____/_| \_/ |_|_| |_|\__, | \_| \_\___/ \___/|_| |_| |_|
                          __/ |
                          |___/
*/

export const sofa_options = [
  {
    value: 'sofa-1',
    green_img: 'sofa-1.svg',
    image: 'sofa-1.svg'
  },
  {
    value: 'sofa-2',
    green_img: 'sofa-2.svg',
    image: 'sofa-2.svg'
  },
  {
    value: 'sofa-3',
    green_img: 'sofa-3.svg',
    image: 'sofa-3.svg'
  }
]

export const sofa_fabric = {
  question: 'Sofas are available in both fabric and leather. Any preference?',
  inputFields: [
    {
      text: 'Leather'
    },
    {
      text: 'Fabric'
    },
    {
      text: 'No preference'
    }
  ]
}

// Radio
export const type_of_sofa = {
  question: 'Is there a particular style of sofa you’d like?',
  inputFields: [
    {
      value: 'l-shaped-sectional',
      text: 'L-shaped sectional sofa',
      green_img: 'n-sofa-1.svg',
      image: 'n-sofa-1.svg'
    },

    {
      value: 'sleeper-sofa',
      text: 'Sleeper Sofa',
      green_img: 'n-sofa-2.svg',
      image: 'n-sofa-2.svg'
    },
    {
      value: 'chaise-sectional',
      text: 'Chaise Sectional Sofa',
      green_img: 'n-sofa-3.svg',
      image: 'n-sofa-3.svg'
    },
    {
      value: 'modular',
      text: 'Modular',
      green_img: 'n-sofa-4.svg',
      image: 'n-sofa-4.svg'
    },
    {
      value: 'no-whatever',
      text: 'No, whatever you think will work best',
      green_img: 'no-sofa.svg',
      image: 'no-sofa.svg'
    }
  ]
}

// Radio with Imagery
export const chair_options = [
  {
    value: 'chair-1',
    green_img: 'chair1.svg',
    image: 'chair1.svg'
  },
  {
    value: 'chair-2',
    green_img: 'chair2.svg',
    image: 'chair2.svg'
  }
]

// Radio
export const chair_fabric = {
  question: 'Chairs are available in both fabric and leather. Any preference?',
  inputFields: [
    { text: 'Leather' },
    { text: 'Fabric' },
    { text: 'No preference' }
  ]
}

// Textarea
export const sofa_notes = {
  question: 'Any other specifics you like in a sofa? (optional)'
}

/*
  ______          _
  | ___ \        | |
  | |_/ / ___  __| |_ __ ___   ___  _ __ ___
  | ___ \/ _ \/ _` | '__/ _ \ / _ \| '_ ` _ \
  | |_/ /  __/ (_| | | | (_) | (_) | | | | | |
  \____/ \___|\__,_|_|  \___/ \___/|_| |_| |_|
*/
export const bed_options = [
  {
    value: 'bed-1',
    green_img: 'bed1.svg',
    image: 'bed1.svg'
  },
  {
    value: 'bed-2',
    green_img: 'bed2.svg',
    image: 'bed2.svg'
  },
  {
    value: 'bed-3',
    green_img: 'bed3.svg',
    image: 'bed3.svg'
  }
]

// Radio
export const bed_size = {
  question: 'What size bed are you looking for?',
  inputFields: [
    { text: 'King' },
    { text: 'Queen' },
    { text: 'Full' }
  ]
}

// Checkbox
export const other_pieces_bedroom = {
  question: 'Other than the basics, would you be interested in anything else for your bedroom?',
  inputFields: [
    { text: 'Chair' },
    { text: 'Chair with ottoman' },
    { text: 'Bench' },
    { text: 'Extra dresser' },
    // { text: 'Office furniture' }, // KEEP: OFFICE
    { text: 'No thanks' }
  ]
}

// Textarea
export const budget_notes = {
  question: 'Like you, we care about costs.',
  text: '<p>Working within a customer’s budget is a priority at toTree, so if you have specific numbers or a range in mind, please jot them down here:</p>'
}

// Text only
export const budget_note = {
  type: 'note',
  title: 'No clue about the costs?',
  text: '<p>Check out <a href="/about/">this quick story</a> from toTree’s founder who was in precisely the same boat when he went to buy quality furniture for the first time.</p><p>If neither of those options sounds appealing and you still have some questions/concerns/thoughts, reach out to lyndsey@totree.co/919-914-9888 and we’ll be happy to help!</p>'
}

/*
  ______ _       _              ______
  |  _  (_)     (_)             | ___ \
  | | | |_ _ __  _ _ __   __ _  | |_/ /___   ___  _ __ ___
  | | | | | '_ \| | '_ \ / _` | |    // _ \ / _ \| '_ ` _ \
  | |/ /| | | | | | | | | (_| | | |\ \ (_) | (_) | | | | | |
  |___/ |_|_| |_|_|_| |_|\__, | \_| \_\___/ \___/|_| |_| |_|
                          __/ |
                          |___/
*/
// Radio
export const dining_room_number_of_people = {
  question: 'How many people do you want to accommodate in your dining space?',
  inputFields: [
    { text: '2-3' },
    { text: '4' },
    { text: '6+' },
    { text: 'Other' }
  ]
}

// Radio
export const table_height = [
  {
    value: 'Bistro',
    label: 'Bistro',
    green_img: 'table-bistro.jpg',
    image: 'table-bistro.jpg'
  },
  {
    value: 'Standard height',
    label: 'Standard height',
    green_img: 'table-standard.png',
    image: 'table-standard.png'
  }
]

// Radio
export const existing_bar_height = {
  question: 'Stools come in two standard sizes (simply measure from the countertop to the floor!)',
  inputFields: [
    { text: 'Kitchen counter stools: (for counter tops 36” high)' },
    { text: 'Bar counter stools: (for counter tops 42” high)' },
    { text: 'My counter does not fall within either option' }
  ]
}
// Text Input
export const number_of_stools_needed = {
  question: 'Enter number of stools needed.'
}

/*
   _____  __  __ _
  |  _  |/ _|/ _(_)
  | | | | |_| |_ _  ___ ___
  | | | |  _|  _| |/ __/ _ \
  \ \_/ / | | | | | (_|  __/
   \___/|_| |_| |_|\___\___|
*/
// Textarea
export const how_tall = {
  question: 'How tall are you?'
}

// Radio
export const chair_type = {
  question: 'Would you like an office desk chair, or a standard chair?',
  inputFields: [
    { text: 'Office chair' },
    { text: 'Standard chair' },
    { text: 'I\'d like to see one of each' }
  ]
}

// Checkbox
export const other_pieces_office = {
  question: 'Do you want any additional pieces in your office?',
  inputFields: [
    { text: 'Chair' },
    { text: 'Ottoman' },
    { text: 'Side table' },
    { text: 'Loveseat or sofa' },
    { text: 'Additional storage' },
    { text: 'No thanks' }
  ]
}

/*
   _______ _________          _        _______
  (  ____ \\__   __/|\     /|( \      (  ____ \
  | (    \/   ) (   ( \   / )| (      | (    \/
  | (_____    | |    \ (_) / | |      | (__
  (_____  )   | |     \   /  | |      |  __)
        ) |   | |      ) (   | |      | (
  /\____) |   | |      | |   | (____/\| (____/\
  \_______)   )_(      \_/   (_______/(_______/
*/

export const color_importance = {
  question: 'On a scale of 1-10, how important is seeing furniture options with a pop of color?'
}

// Checkbox
export const favorite_color = {
  question: 'Pick a color, any color!',
  inputFields: [
    {
      text: 'Reds'
    },
    {
      text: 'Blues'
    },
    {
      text: 'Greens'
    }
  ]
}

// Textarea
export const style_notes = {
  question: 'Something we didn’t cover?'
}

export const inspiration = {
  question: 'Inspiration? Images? Upload pictures, links or Pinterest boards here:'
}

// Radio
export const sofa_placement = {
  question: 'Where are you going to place your sofa?',
  inputFields: [
    { text: 'Wall A' },
    { text: 'Wall B' },
    { text: 'I don\'t know, can you help me?' }
  ]
}

// Textarea
export const room_notes = {
  question: 'Anything additional to note for this room?'
}
