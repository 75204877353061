const furnitureSelection = require('@/test-furniture-selection.json')
const apiKey = 'test-api-key-abc123' // This key will only work in dev / staging environments
const apiRoot = process.env.VUE_APP_FIREBASE_API_ROOT

const actions = {
  async addSelectionToProject ({ rootState }, payload) {
    try {
      const res = await fetch(`${apiRoot}/addSelections`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `x-api-key ${apiKey}`
        },
        body: JSON.stringify({
          user: payload.user, // The user associated with these selections
          survey: payload.survey, // The survey / quiz associated with these selections
          boards: furnitureSelection.boards,
          skipImagesReupload: true // Skip reuploaded Cloudinary images if they're already present
        })
      })
      const data = await res.json()

      alert(data.message)
      window.location.reload()
    } catch (err) {

    }
  },

  async addOnSiteServiceDetails ({ rootState }, payload) {
    try {
      const res = await fetch(`${apiRoot}/updateOnSiteService`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `x-api-key ${apiKey}`
        },
        body: JSON.stringify({
          user: payload.user, // The user associated with these selections
          survey: payload.survey, // The survey / quiz associated with these selections
          representative: 'John Doe',
          date: '2022-09-28T21:42:00.000Z'
        })
      })
      await res.json()
      window.location.reload()
    } catch (err) {

    }
  },

  async markOnSiteServiceComplete ({ rootState }, payload) {
    try {
      const res = await fetch(`${apiRoot}/updateOnSiteService`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `x-api-key ${apiKey}`
        },
        body: JSON.stringify({
          user: payload.user, // The user associated with these selections
          survey: payload.survey, // The survey / quiz associated with these selections
          status: 'complete'
        })
      })
      await res.json()
      window.location.reload()
    } catch (err) {

    }
  }
}

export default {
  actions
}
