/**
 * This file contains global helper methods that can be used anywhere to use the same code
 * in different components.
 *
 * This should contain simple functions that are local to the application. Meaning no async functions
 * if possible (instead use Vuex)
 * Reference:
 * https://codingblast.com/organising-javascript-helpers/
 * https://codingblast.com/organising-javascript-helpers/#:~:text=Cleanest%20option%20%E2%80%93%20Export%20class%20with%20static%20methods
 */

// Leading 0s here are read as octals

const ossZipCodeRanges = [
  [10001, 10090],
  [10103, 10178],
  [10270, 10282],
  [10301, 10314],
  [11201, 11256],
  [10451, 10475],
  [11101, 11109],
  [11351, 11436],
  [7302, 7311],
  [7030],
  [10801, 10805],
  [10701, 10710],
  [10601, 10605],
  [10501, 10598],
  [6830, 6897],
  [11101 - 11106]
]

export const inOssZipCodeRange = function (zip) {
  let inRange = false
  // Loop through all the zip code ranges
  for (let i = 0; i < ossZipCodeRanges.length; i++) {
    const start = ossZipCodeRanges[i][0]
    const end = ossZipCodeRanges[i][1]

    // If using start and end, validate that it's between the extremes
    if (start && end) {
      if (zip >= start && zip <= end) {
        inRange = true
      }
    } else if (start) {
      if (parseInt(zip) === start) {
        inRange = true
      }
    }
  }
  return inRange
}

export const getStateFromZipUS = function (zipString, config) {
  /* Ensure param is a string to prevent unpredictable parsing results */
  if (typeof zipString !== 'string') {
    return
  }

  /* Ensure we have exactly 5 characters to parse */
  if (zipString.length !== 5) {
    return
  }

  /* Ensure we don't parse strings starting with 0 as octal values */
  const zipcode = parseInt(zipString, 10)

  let stateAbbreviation
  let stateFullname

  /* Code cases alphabetized by stateFullname */
  if (zipcode >= 35000 && zipcode <= 36999) {
    stateAbbreviation = 'AL'
    stateFullname = 'Alabama'
  } else if (zipcode >= 99500 && zipcode <= 99999) {
    stateAbbreviation = 'AK'
    stateFullname = 'Alaska'
  } else if (zipcode >= 85000 && zipcode <= 86999) {
    stateAbbreviation = 'AZ'
    stateFullname = 'Arizona'
  } else if (zipcode >= 71600 && zipcode <= 72999) {
    stateAbbreviation = 'AR'
    stateFullname = 'Arkansas'
  } else if (zipcode >= 90000 && zipcode <= 96699) {
    stateAbbreviation = 'CA'
    stateFullname = 'California'
  } else if (zipcode >= 80000 && zipcode <= 81999) {
    stateAbbreviation = 'CO'
    stateFullname = 'Colorado'
  } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
    stateAbbreviation = 'CT'
    stateFullname = 'Connecticut'
  } else if (zipcode >= 19700 && zipcode <= 19999) {
    stateAbbreviation = 'DE'
    stateFullname = 'Delaware'
  } else if (zipcode >= 32000 && zipcode <= 34999) {
    stateAbbreviation = 'FL'
    stateFullname = 'Florida'
  } else if ((zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999)) {
    stateAbbreviation = 'GA'
    stateFullname = 'Georgia'
  } else if (zipcode >= 96700 && zipcode <= 96999) {
    stateAbbreviation = 'HI'
    stateFullname = 'Hawaii'
  } else if (zipcode >= 83200 && zipcode <= 83999) {
    stateAbbreviation = 'ID'
    stateFullname = 'Idaho'
  } else if (zipcode >= 60000 && zipcode <= 62999) {
    stateAbbreviation = 'IL'
    stateFullname = 'Illinois'
  } else if (zipcode >= 46000 && zipcode <= 47999) {
    stateAbbreviation = 'IN'
    stateFullname = 'Indiana'
  } else if (zipcode >= 50000 && zipcode <= 52999) {
    stateAbbreviation = 'IA'
    stateFullname = 'Iowa'
  } else if (zipcode >= 66000 && zipcode <= 67999) {
    stateAbbreviation = 'KS'
    stateFullname = 'Kansas'
  } else if (zipcode >= 40000 && zipcode <= 42999) {
    stateAbbreviation = 'KY'
    stateFullname = 'Kentucky'
  } else if (zipcode >= 70000 && zipcode <= 71599) {
    stateAbbreviation = 'LA'
    stateFullname = 'Louisiana'
  } else if (zipcode >= 3900 && zipcode <= 4999) {
    stateAbbreviation = 'ME'
    stateFullname = 'Maine'
  } else if (zipcode >= 20600 && zipcode <= 21999) {
    stateAbbreviation = 'MD'
    stateFullname = 'Maryland'
  } else if ((zipcode >= 1000 && zipcode <= 2799) || (zipcode === 5501) || (zipcode === 5544)) {
    stateAbbreviation = 'MA'
    stateFullname = 'Massachusetts'
  } else if (zipcode >= 48000 && zipcode <= 49999) {
    stateAbbreviation = 'MI'
    stateFullname = 'Michigan'
  } else if (zipcode >= 55000 && zipcode <= 56899) {
    stateAbbreviation = 'MN'
    stateFullname = 'Minnesota'
  } else if (zipcode >= 38600 && zipcode <= 39999) {
    stateAbbreviation = 'MS'
    stateFullname = 'Mississippi'
  } else if (zipcode >= 63000 && zipcode <= 65999) {
    stateAbbreviation = 'MO'
    stateFullname = 'Missouri'
  } else if (zipcode >= 59000 && zipcode <= 59999) {
    stateAbbreviation = 'MT'
    stateFullname = 'Montana'
  } else if (zipcode >= 27000 && zipcode <= 28999) {
    stateAbbreviation = 'NC'
    stateFullname = 'North Carolina'
  } else if (zipcode >= 58000 && zipcode <= 58999) {
    stateAbbreviation = 'ND'
    stateFullname = 'North Dakota'
  } else if (zipcode >= 68000 && zipcode <= 69999) {
    stateAbbreviation = 'NE'
    stateFullname = 'Nebraska'
  } else if (zipcode >= 88900 && zipcode <= 89999) {
    stateAbbreviation = 'NV'
    stateFullname = 'Nevada'
  } else if (zipcode >= 3000 && zipcode <= 3899) {
    stateAbbreviation = 'NH'
    stateFullname = 'New Hampshire'
  } else if (zipcode >= 7000 && zipcode <= 8999) {
    stateAbbreviation = 'NJ'
    stateFullname = 'New Jersey'
  } else if (zipcode >= 87000 && zipcode <= 88499) {
    stateAbbreviation = 'NM'
    stateFullname = 'New Mexico'
  } else if ((zipcode >= 10000 && zipcode <= 14999) || (zipcode === 6390) || (zipcode === 501) || (zipcode === 544)) {
    stateAbbreviation = 'NY'
    stateFullname = 'New York'
  } else if (zipcode >= 43000 && zipcode <= 45999) {
    stateAbbreviation = 'OH'
    stateFullname = 'Ohio'
  } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999)) {
    stateAbbreviation = 'OK'
    stateFullname = 'Oklahoma'
  } else if (zipcode >= 97000 && zipcode <= 97999) {
    stateAbbreviation = 'OR'
    stateFullname = 'Oregon'
  } else if (zipcode >= 15000 && zipcode <= 19699) {
    stateAbbreviation = 'PA'
    stateFullname = 'Pennsylvania'
  } else if (zipcode >= 300 && zipcode <= 999) {
    stateAbbreviation = 'PR'
    stateFullname = 'Puerto Rico'
  } else if (zipcode >= 2800 && zipcode <= 2999) {
    stateAbbreviation = 'RI'
    stateFullname = 'Rhode Island'
  } else if (zipcode >= 29000 && zipcode <= 29999) {
    stateAbbreviation = 'SC'
    stateFullname = 'South Carolina'
  } else if (zipcode >= 57000 && zipcode <= 57999) {
    stateAbbreviation = 'SD'
    stateFullname = 'South Dakota'
  } else if (zipcode >= 37000 && zipcode <= 38599) {
    stateAbbreviation = 'TN'
    stateFullname = 'Tennessee'
  } else if ((zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 73301 && zipcode <= 73399) || (zipcode >= 88500 && zipcode <= 88599)) {
    stateAbbreviation = 'TX'
    stateFullname = 'Texas'
  } else if (zipcode >= 84000 && zipcode <= 84999) {
    stateAbbreviation = 'UT'
    stateFullname = 'Utah'
  } else if (zipcode >= 5000 && zipcode <= 5999) {
    stateAbbreviation = 'VT'
    stateFullname = 'Vermont'
  } else if ((zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || (zipcode === 20598)) {
    stateAbbreviation = 'VA'
    stateFullname = 'Virginia'
  } else if ((zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999)) {
    stateAbbreviation = 'DC'
    stateFullname = 'Washington DC'
  } else if (zipcode >= 98000 && zipcode <= 99499) {
    stateAbbreviation = 'WA'
    stateFullname = 'Washington'
  } else if (zipcode >= 24700 && zipcode <= 26999) {
    stateAbbreviation = 'WV'
    stateFullname = 'West Virginia'
  } else if (zipcode >= 53000 && zipcode <= 54999) {
    stateAbbreviation = 'WI'
    stateFullname = 'Wisconsin'
  } else if (zipcode >= 82000 && zipcode <= 83199) {
    stateAbbreviation = 'WY'
    stateFullname = 'Wyoming'
  } else {
    stateAbbreviation = 'none'
    stateFullname = 'none'
  }

  if (config === 'full') {
    return stateFullname
  } else {
    return stateAbbreviation
  }
}

export const blobToDataURI = function (blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = () => {
      reject(reader.error)
    }
    reader.readAsDataURL(blob)
  })
}

export const validateEmailString = function (emailString) {
  const response = {}

  // RegEx Format: RFC 5322 Format
  // https://stackabuse.com/validate-email-addresses-with-regular-expressions-in-javascript/
  // Use the following comment to disable eslint error
  // "Unnecessary escape character: \.  no-useless-escape"
  // See Example: https://stackoverflow.com/questions/47277133/disable-unnecessary-escape-character-no-useless-escape
  // eslint-disable-next-line
  // const emailRegEx = /^\S+@\S+\.\S+$/ // Added a 'more forgiving' email regex
  // eslint-disable-next-line
  // const emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,32})+$/
  // eslint-disable-next-line
  // const emailRegEx = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])") // allows "test@" as a valid email
  // eslint-disable-next-line
  const emailRegEx = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,30}$/g // https://regexr.com/3e48o valid for "abc+ABC.123@ABC_abc-0123456789.com_32-characters"

  if (emailString && emailRegEx.test(emailString)) {
    // A valid email based on emailRegEx

    response.emailString = emailString
    response.isValid = true
    response.message = ''

    return response
  } else {
    // Not a valid email based on emailRegEx

    response.emailString = emailString
    response.isValid = false
    response.message = 'Please enter a valid email address'

    return response
  }
}

/**
 * Takes an input string and checks if it's a valid US phone number format
 * @param {*} testValue string
 * @returns validationResponse
 */
export const validatePhoneNumber = function (testValue) {
  const response = {
    testValue: testValue
  }
  // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
  // eslint-disable-next-line
  const phoneRegEx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

  if (testValue && phoneRegEx.test(testValue)) {
    // A valid email based on emailRegEx
    response.isValid = true
    response.message = ''

    return response
  } else {
    // Not a valid email based on emailRegEx
    response.isValid = false
    response.message = 'Please enter a valid phone number'

    return response
  }
}

/**
 * Takes a validated US phone String and formats it so it's pretty
 * @param {*} validPhoneString
 * @returns formatted US Phone Number String
 */
export const formatPhoneString = function (validPhoneString) {
  // Filter only numbers from the input
  const cleaned = ('' + validPhoneString).replace(/\D/g, '')

  // Check if the input is of correct
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    // Remove the matched extension code
    // Change this to format for any country code.
    const intlCode = (match[1] ? '+1 ' : '')
    const prettyPhoneString = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')

    return prettyPhoneString
  }

  return null
}

export const validateAndFormatPhone = function (testPhoneString) {
  let checkPhone = validatePhoneNumber(testPhoneString)

  checkPhone.testValueOriginal = checkPhone.testValue

  if (checkPhone?.isValid) {
    const formattedPhoneString = formatPhoneString(checkPhone.testValue)
    checkPhone = { ...checkPhone, ...{ testValue: formattedPhoneString } }

    return checkPhone
  } else {
    return checkPhone
  }
}

export const validatePasswordString = function (passString) {
  const response = {}
  const passMinLength = 6 // firebase required password length

  if (passString && passString.length > passMinLength) {
    // valid password format

    response.isValid = true
    response.message = ''

    return response
  } else {
    // invalid password format

    response.passString = passString
    response.isValid = false
    response.message = 'This password is invalid (too short).'

    return response
  }
}

/**
 * Capitalize each word in a string.
 */
export const capitalizeWords = function (string) {
  if (typeof string !== 'string') {
    return ''
  }

  return string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase()
  })
}

/**
 * Formats a Number like 1999999.88 to 1,999,999.88
 * @param {*} number
 * @returns number formatted number currency in USD.
 */
export const formatCurrency = function (number) {
  if (!number && number !== 0) {
    return '...'
  }

  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
  })

  return currency.format(number)
}

export const randomIntFromInterval = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * Returns a formatted cloudinary URL for an existing Cloudinary image after adding the specified width.
 */
export const cloudinaryImage = function (url, width) {
  //
  const updatedUrl = url.replace('image/upload/', `image/upload/w_${width}/`)
  return updatedUrl
}
