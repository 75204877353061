<template>
  <div class="social__slideshow">
    <div class="social__slideshow__slide item" v-for="(post, index) in posts" :key="`slide-image-${index}`">
      <figure><img :src="post.imageUrl"></figure>
      <p class="testdesc">
        <template v-if="post.isBold">
          <strong>{{ post.short_content }}</strong> {{ post.content }}
        </template>
        <template v-else>
          {{ post.short_content }} <strong>{{ post.content }}</strong>
        </template>
      </p>
      <div class="testauthor">
        <p><strong>{{post.name}}</strong>{{post.location}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from 'flickity'
import 'flickity/dist/flickity.min.css'

export default {
  name: 'TestimonialList',
  methods: {
    slideshow: function () {
      if (window.innerWidth < 420) {
        new Flickity('.social__slideshow', { // eslint-disable-line no-new
          imagesLoaded: true,
          wrapAround: true,
          prevNextButtons: false,
          groupCells: true,
          freeScroll: false,
          pageDots: true,
          autoPlay: 2200
        })
      } else {
        new Flickity('.social__slideshow', { // eslint-disable-line no-new
          imagesLoaded: true,
          wrapAround: true,
          prevNextButtons: false,
          groupCells: true,
          freeScroll: true,
          pageDots: false,
          autoPlay: 2200
        })
      }
    }
  },
  data () {
    return {
      posts: [
        { name: 'Adam B.', location: 'Brooklyn, NY', short_content: "I'd recommend them to anyone like me:", content: 'someone who finds buying new furniture a total hassle.', imageUrl: 'img/testimonal/testimonal-adam.png', isBold: true },
        { name: 'Abby L.', location: 'Los Angeles, CA', short_content: 'With a full-time job and a 2 year-old, I don’t have time to shop. Luckily, a friend told me about toTree. While I loved the selections they picked for me,', content: 'the real game-changer was having my own specialist to answer all of my questions.', imageUrl: 'img/testimonal/testimonal-abby.png', isBold: false },
        { name: 'Chris B.', location: 'New York, NY', short_content: 'The process was fast and efficient and so easy that my 8 year-old nephew could use it.', content: 'The furniture? Even better. And from the popular brands that I like and trust.', imageUrl: 'img/testimonal/testimonal-chris.png', isBold: true },
        { name: 'Lindsay M.', location: 'Los Angeles, CA', short_content: 'While I usually love furniture shopping, I’m glad I used toTree this time. My new sofa arrived with an ink stain on one of the pillows.  Instead of spending hours on the phone,', content: 'with one quick email to toTree, I had a new pillow within two days.', imageUrl: 'img/testimonal/testimonal-lindsay.png', isBold: false }
      ]
    }
  },
  mounted () {
    this.slideshow()
  }
}
</script>

<style lang="scss">
.testimonial .item figure {
  margin: 0 auto 2rem;
}
.flickity-cell {
  width: 25%;
}
</style>
