<template>
  <div class="form__question">
    <div class="form__content">
      <legend class="quiz__subheading">
        Thanks for taking our survey. Your customized style boards will be ready within 72 hours!
      </legend>
    </div>
    <div class="initial-results">
      <div class="initial-results__item">
        <img :src="resultImage1">
      </div>
      <div class="initial-results__item">
        <img :src="resultImage2">
      </div>
      <div class="initial-results__item">
        <img :src="resultImage3">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      resultImage1: '/img/example-item-1.jpg',
      resultImage2: '/img/example-item-2.jpg',
      resultImage3: '/img/example-item-3.jpg'
    }
  }
}
</script>

<style lang="scss" scoped>
.initial-results {
  display: contents;

  .initial-results__item {
    grid-column: span 4;
  }
}
</style>
