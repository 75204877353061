<template>
  <div class="form__style-radio-4 form__style-radio--carousel">
    <div class="form__content--wide">
      <div class="slides">
        <div v-for="(rug, index) in options" :key="`rug-${index}`" class="slide">
          <div class="form__item form__item--radio custom-checkbox custom_New_checkbox">
            <label :for="`style-${index}`">
              <img :src="require('@/assets/images/' + rug.image)">
            </label>
            <input
              v-model="chosenValues"
              :value="rug.value"
              type="checkbox"
              :id="`style-${index}`"
              name="style"
            >
          </div>
        </div>
      </div>
      <ValidationWarning :valid="valid" />
    </div>
  </div>
</template>

<script>
import Flickity from 'flickity'
import 'flickity/dist/flickity.min.css'

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: true,
      options: [
        {
          value: 'border',
          image: 'rug2.jpg'
        },
        {
          value: 'geometric',
          image: 'rug1.jpg'
        },
        {
          value: 'moroccan',
          image: 'rug4.jpg'
        },
        {
          value: 'southwestern',
          image: 'rug3.jpg'
        },
        {
          value: 'solid',
          image: 'rug5.jpg'
        }
      ],
      chosenValues: [],
      initialized: false
    }
  },
  mounted () {
    this.slideshow()
    this.chosenValues = this.modelValue || []
  },
  methods: {
    validate () {
      this.valid = !(this.required && !this.chosenValues.length && this.initialized)
      return this.valid
    },
    slideshow () {
      var args = {
        imagesLoaded: true,
        wrapAround: false,
        freeScroll: true,
        pageDots: false,
        prevNextButtons: false,
        initialIndex: 2 // center image
      }
      // eslint-disable-next-line no-new
      new Flickity('.slides', args)
    }
  },
  watch: {
    chosenValues: {
      handler () {
        this.$emit('update:modelValue', this.chosenValues)
        this.validate()
        this.initialized = true
      },
      deep: true
    }
  }
}
</script>
