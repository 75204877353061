<template>
  <!-- <div class="modal-backdrop modelStyle MakeSelection" :class="{ blurred: blurredBackground }"> -->
  <div :class="{ 'modal-backdrop modelStyle' : true, 'MakeSelection' : fromTimeMixPopup, blurred: blurredBackground}">
    <div class="hitbox" style="backdrop-filter: blur(5px);" @click="close" />
    <div class="modal"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
    >
      <header
        class="modal-header offer-header"
        id="modalTitle">
        <slot name="header">
          <div class="modelLogo"><img src="@/assets/theme/images/logo-color.svg"></div>
          <button
          v-if="hasCloseListener"
          class="modal__close custom_close"
          type="button"
          @click="$emit('close')"
          aria-label="Close modal"
        >
          Close
        </button>
        </slot>
      </header>
      <section
        class="modal-body box-section"
        :class="{ centered: centered }"
        id="modalDescription"
        :style="maxBodyWidth ? `max-width: ${maxBodyWidth}px; margin-left: auto; margin-right: auto` : ''"
      >
        <slot name="body">
          Default body
        </slot>
        <!-- <button
          v-if="fromTimeMixPopup"
          class="modal__close custom_close"
          type="button"
          @click="$emit('close')"
          aria-label="Close modal"
        >
          Close
        </button> -->
      </section>

      <footer class="modal-footer rm-border" :class="{ 'two-actions': cancelLabel }">
        <slot name="footer">
          <button
            v-if="!hideActions"
            type="button"
            @click="confirm"
            aria-label="Confirm"
            :class=" { 'block-half' : true, 'customGreenBTN' : fromTimeMixPopup, [confirmedButtonClass]: true }"
          >
            {{ confirmLabel }}
          </button>
          <button
            v-if="!hideActions && cancelLabel"
            type="button"
            @click="$emit('cancel')"
            aria-label="Cancel"
            class="invert block-half"
          >{{cancelLabel}}
          </button>
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    fromTimeMixPopup: {
      type: Boolean,
      default: false
    },
    confirmLabel: {
      type: String,
      default: 'OK'
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    cancelLabel: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    },
    maxBodyWidth: {
      type: Number,
      default: null
    },
    blurredBackground: {
      type: Boolean,
      default: false
    },
    confirmedButtonClass: {
      type: String,
      default: 'invert'
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    confirm () {
      this.$emit('confirm')
    }
  },
  computed: {
    hasCloseListener () {
      return this.$attrs && this.$attrs.onClose
    }
  }
}
</script>

<style lang="scss">
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    &.blurred {
      backdrop-filter: blur(5px);
    }

    .hitbox {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .HWEModel .modal {
    background-color: #fff;
    color: #000;
    width: 801px;
    padding: 3rem 2rem 0rem;
  }
   .HWEModel.offer-modal  .modal {
    border:8px solid #3f5929 !important;
    border-radius: 80px;
    padding: 3rem 2rem;
   }
  .HWEModel .footer {
  text-align: left;
}
 .HWEModel.offer-modal  .offer-header{
  display:none !important;
}
@media (max-width:400px){
.HWEModel.offer-modal  .modal {
  min-width: 315px;
  }
}
@media (max-width:768px){
  .HWEModel.offer-modal  .modal {
    border-radius: 30px;
    padding: 1rem 2rem;
   }
}
@media (min-width:992px){
  .HWEModel .footer {
  padding-left: 15%;
}
}
@media (max-width: 992px) and (orientation: landscape) {
  .HWEModel .modal { width:95%; height:100%}
  .modal-footer {
    position: relative;
}
 .HWEModel .modal-body {
      position: relative;
    z-index: 2;
 }
.modal__close {
    top: 0rem !important;
    left: 1rem !important;
}
}
@media (min-width: 800px) and (max-width: 920px) and (orientation: landscape) {
  .modelText {
       padding: 6rem 4rem 11rem !important;
  }
}
@media (max-width:583px){
.modelText {
    padding: 3rem 7rem 5rem !important;
    max-width: 100% !important;
    margin: 0 auto;
    font-size: 10px;
    background-size: contain !important;
}
   .HWEModel .modal-body {
      position: relative;
    z-index: 2;
 }
 .modal__close {
    top: 0rem !important;
    left: 1rem !important;
}
}
@media (max-width:535px){
  .modelText {
        padding: 6rem 1rem 8rem !important;
  }
}
@media (max-width:290px){
  .HWEModel .modal {
    width: 100%;
    min-width: auto;
    padding: 3rem 1rem 0rem;
}

 .modal__close {
    top: -1rem !important;
    left: 0rem !important;
 }
}
  .modal {
    position: relative;
    background: $tt-green;
    box-shadow: 0 .5em 1em rgba(0, 0, 0, .25);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    color: white;
    min-width: 350px;
    width: 710px;
    padding: 3rem 4rem;

    @include breakpoint($s) {
      padding: 2rem 2rem;
    }
  }

  .modal-header,
  .modal-footer {
    display: flex;
  }

  .modal-header {
    @include h1;
    padding-top: 0;
    padding-bottom: 2rem;
    position: relative;
    justify-content: space-between;
    text-align: center;
    display: inline;
    color: white;
  }

  .modal-footer {
    padding: 1rem 0;
    flex-direction: column;
    font-size: 0.8rem;
    text-align: center;

    &.two-actions {
      flex-direction: row;
      gap: var(--gutter);
      button {
        flex: 1;
      }
    }
  }

  .modal-body {
    &.centered {
      text-align: center;
    }
    p {
      margin-bottom: .5em;
    }
    .quiz__subheading {
      font-family: 'GT America Regular';
      font-size: 1rem;
    }
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }

  .modal input[type=email],
  .modal input[type=password]
  .modal input[type=text] {
    color: white;

    @include placeholder-color(rgba(255, 255, 255, .7));

    &:focus {
      background: rgba(255, 255, 255, .2);
    }
  }

  .modal .form__item--text::before,
  .modal .form__item--select::before {
    border-color: white;
  }

  .modal .warning {
    text-align: center;
  }
  .time_mix_popup_close {
    position: absolute !important;
    top: 2rem !important;
    left: 2rem !important;
  }
  .modal__close {
    position: absolute;
    top: 2rem;
    left: 2rem;
    background: url('../assets/images/close-no-bg_new.svg') center center no-repeat;
    background-size: 2rem auto;
    border-radius: 0;
    padding: 0;
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    text-indent: -9999px;
    border: 0;

    &:hover {
      background-color: transparent;
    }
  }
  @media (max-width: 290px){
  .modal__close {
      top: 1rem !important;
      left: 0 !important;
    }
  }
  @media (max-width:583px){
    .HWEModel .box-section{
      position:initial !important;
    }
    .modal__close{
      top: 1rem !important;
      left: 1rem !important;
    }
  }
  .HWEModel.offer-modal .modal{
    border: 18px solid #566b5d !important;
  }
  @media (max-width:567px){
    .HWEModel.offer-modal .modal {
      padding:10px;
    }
  }
  @media (max-width:300px){
  .HWEModel.offer-modal .modal {
    min-width: 235px;
    padding:10px;
  }
}
.loader .overlay{
  border-radius: 20px !important;
}
@media (min-width: 1200px) and (max-width: 1400px) {
   .HWEModel.offer-modal .modal {
      padding: 0rem 0rem;
   }
}
</style>
