<template>
  <Modal class="HWEModel offer-modal" @close="closeModalOffer">
    <!-- <template v-slot:header>
      <transition name="fade" mode="out-in">
        <h1 :key="modalTitle">{{ modalTitle }}</h1>
      </transition>
    </template> -->

    <template v-slot:body>
      <transition name="fade" mode="out-in">
        <div class="modelText1 offer-modal-text">
          <div class="modal-top-logo">
            <img class="" src="@/assets/images/websitelogo.png" />
          </div>
          <h3><span class="holy-icon">'</span>Holy Sh*P!'</h3> <br>
          <h3>Free shipping Spectacular!</h3>
          <p>
            Upgrading your sofa? Renting out your guest room?<br>
            Furnishing your entire new home?<br>
            Save serious $$$ with toTree!<br>
            <span class="underline">Every order</span> through December 31st 2023 comes with
          </p>
              <!-- <h2> <span class="dollar-icon">$</span>5,000!</h2> -->
              <h3 class='custom-size'>Free shipping</h3>
          <div class="modal-center-img">
            <img class="" src="@/assets/images/furnitures.png" />
          </div>
        </div>
      </transition>
      <Loader :visible="loading" blocking />
    </template>
    <template v-slot:footer>
      <p class="footer">(offer not valid with any other promotions)</p>
      <!-- <p class="footer">No conditions!</p>
      <p class="footer">No strings!</p>
      <p class="footer">No BS!</p>
      <p class="footer">(winner will be selected on January 4th, 2024)</p>
      <p class="footer" @click="closeModalOffer"><router-link to="/terms-conditions" ><b>Terms & Conditions</b></router-link></p> -->
    </template>
  </Modal>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import { validateEmailString, validatePasswordString } from '@/assets/scripts/helpers'
import Modal from '@/components/Modal'
import Loader from '@/components/Loader'

export default {
  name: 'ModalOffer',
  components: {
    Modal,
    Loader
  },
  data () {
    return {
      email_address: '',
      password: '',
      errorMessage: null,
      errorMessageForgot: null,
      forgotPassword: false,
      loading: false,
      showPassword: false
    }
  },
  computed: {
    ...mapState(['firebaseAuth', 'firestore', 'fbFunctions']),
    ...mapGetters(['currentUser', 'userProfile']),
    modalTitle () {
      return 'AdditionalInfo'
    }
  },

  methods: {
    ...mapActions([
      'userAuthCheck'
    ]),

    ...mapMutations([
      'setModalOffer',
      'setGlobalNotification',
      'setModalSignup'
    ]),
    closeModalSignup () {
      this.setModalSignup(false)
    },
    showModalSignup () {
      this.setModalSignup(true)
      this.setModalOffer(false)
    },
    closeModalOffer () {
      // This is emitted to the parent.
      setTimeout(() => {
        this.forgotPassword = false
      }, 300)
      this.$emit('closeModalOfferEvent')
    },

    async signInUserAccount () {
      // validating email format. signInWithEmailAndPassword is not specifying an invalid email format error.
      const checkEmail = validateEmailString(this.email_address)
      if (!checkEmail.isValid) {
        this.errorMessage = 'This email address is invalid.'
        return false
      }

      // validating password format. signInWithEmailAndPassword is not specifying missing or short password error.
      const checkPass = validatePasswordString(this.password)
      if (!checkPass.isValid) {
        this.errorMessage = checkPass.message
        return false
      }

      try {
        await signInWithEmailAndPassword(this.firebaseAuth, this.email_address, this.password)

        await this.userAuthCheck()

        if (this.currentUser.email) {
          this.$router.push('/projects')
        }

        this.setModalOffer(false)
      } catch (error) {
        // error.code, error.message are returned from FirebaseAuth
        // see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithemailandpassword for error types.

        switch (error.code) {
          case 'auth/missing-email':
            this.errorMessage = 'Please enter an email addresss.'
            break
          case 'auth/invalid-email':
            this.errorMessage = 'This email address is invalid.'
            break
          case 'auth/user-disabled':
            this.errorMessage = 'This account has been disabled.'
            break
          case 'auth/user-not-found':
            this.errorMessage = 'user-not-found'
            break
          case 'auth/wrong-password':
            this.errorMessage = 'The password does not match the account.'
            break
          case 'auth/network-request-failed':
            this.errorMessage = 'A network error has occurred. Please try again later.'
            break
          default:
            this.errorMessage = 'An unknown login error has occurred. ' + error
            break
        }
      }
    },

    /**
     * Note that, in the local emulators, the email won't be sent.
     * Rather, it'll appear in the emulator console as a link to modify and visit.
     */
    async initiatePasswordReset () {
      this.errorMessageForgot = null
      this.loading = true
      try {
        // await sendPasswordResetEmail(this.firebaseAuth, this.email_address)
        await httpsCallable(this.fbFunctions, 'sendgrid-passwordResetEmail')({
          to: this.email_address
        })

        this.loading = false
        this.closeModalOffer()
        this.setGlobalNotification('Email Sent')
      } catch (err) {
        this.loading = false

        this.errorMessageForgot = err.message
      }
    }
  }
}
</script>

<style scoped lang="scss">
.underline{ text-decoration:underline}
.modelText {
  background-image: url(../assets/images/home/message_modal.png);
  background-size: cover;
  background-position: center;
  font-size: 15px;
  padding: 4rem 3.5rem 8rem;
  text-align: center;
  max-width: 85%;
}
.modelBottom {
  position: absolute;
  right: 0;
  bottom: 0;
}
.holy-icon{
  rotate: 190deg;
    display: inline-block;
    top: -10px;
    left: -10px;
    position: absolute;
}

.modelBottom img {
  max-width: 200px;
}
@media(max-width:767px){
  .modelText {
    background-size: 100%;
    padding: 6rem 1rem 11rem;
    max-width: 100%;
    font-size: 11px;
    background-repeat: no-repeat;
    line-height:normal;
    background-image: url(../assets/images/home/mobile_message_moda.png);
  }
  .holy-icon{
    left: -7px;
  }
}
@media (max-width: 992px) and (orientation: landscape) {
   .modelText {
    background-size: contain;
    max-width: 83% !important;
    font-size: 1rem !important;
    background-repeat: no-repeat;
    line-height: normal;
    background-image: url(../assets/images/home/mobile_message_moda.png);
    background-position: center top;
        min-height: 400px;
  }

}
@media (max-width: 767px){
.modelText{
    padding: 6rem 1rem 8rem;
    max-width: 95%;
    margin: 0 auto;
    font-size: 10px;
}
.HWEModel .footer {
    position: relative;
    z-index: 1;
}
.HWEModel .footer .primary  {
    display: block;
    max-width: 81px;
    height: auto;
}
}
@media(max-width:290px){
  .modelText {
    padding: 3rem 1rem 5rem !important;
    max-width: 100%  !important;
    margin: 0 auto;
    font-size: 9px;
    background-size: contain;
  }
}

.SInOption {margin-right: 1rem;}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0 30px $tt-walnut inset !important;
  transition: background-color 5000s ease-in-out 0s; // hack to get background color to go transparent see background-color
  background-color: transparent !important;
  background-image: none !important;
}
.show-password__reveal--text {
  color: white
}
input[type=email]:hover,
input[type=email]:active,
input[type=email]:focus {
  background: $tt-green;
}
#email {
  background: $tt-green;
}
#password:hover,
#password:active,
#password:focus {
  color: white;
  background: transparent;
}
#password {
  color: white;
  &:focus {
    background: white;
    color: white;
    background: transparent;
  }
}
#password::placeholder {
  color: rgba(255, 255, 255, .7);
}

input.show-password__reveal--text {
  color: black;

  @include placeholder-color($tt-grey-20);
  &:focus {
      background: white;
      color: black
    }
}
.form__item--warning {
  clear: both;
}
.show-password-toggle {
  color: white;
}
.form__item--checkbox {
  display: inline;
  float: left;
}
.form__item--submit {
  clear: both;
}
.footer {
  .underline {
    cursor: pointer;
    text-decoration: underline;
  }
}
button[type="submit"] {
  margin-top: 2rem;
}
.offer-modal{
  text-align: center;
}
.offer-modal .offer-modal-text h3{
  font-size: 36px;
  margin: 20px 0;
  font-family: frankfurtregular;
  text-transform: uppercase;
  position:relative;
  display:inline-block;
}
.offer-modal .offer-modal-text p{
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  padding: 0 104px;
  margin: 20px 0;
  margin-bottom: 0;
  font-family: "GT America Expanded";
}
.offer-modal .offer-modal-text h2{
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 20px;
  margin-top: 5px;
  font-family: "GT America Expanded";
}
.offer-modal .offer-modal-text h2 .dollar-icon{
  font-family: auto;
}
.offer-modal .footer{
  text-align: center;
  font-size: 14px;
  padding-left: 0;
  margin-bottom:12px;
  font-family: "GT America Expanded";
}
.offer-modal .footer a{
  text-decoration: underline;
}
.offer-modal .modal-center-img img{
  max-width: 400px;
  width: 400px;
  height: 110px;
  min-height:110px;
}
.offer-modal .modal-top-logo{
  margin-bottom:20px;
}
.offer-modal .modal-top-logo img{
  height: 143px;
  min-height:143px;
}
.offer-modal .offer-header{
  padding:0 !important;
}
.site-header.invert .site-header__nav__item a {
  color:#000 !important;
  text-decoration:underline;
}
.custom-size{
  font-size: 65px !important;
  margin-top: 25px  !important;
}
@media(max-width:576px){
  .custom-size{
  font-size: 45px !important;
  }
}
@media(max-width:400px){
  .custom-size{
  font-size: 25px !important;
  margin-top:8px !important;
  }
   .holy-icon{
    top:-6px;
    left: -6px;
  }
}
@media(max-width:768px){
  .HWEModel.offer-modal .modal{
    padding: 1rem 1rem;
    border-radius: 30px;
  }
  .offer-modal .offer-modal-text h3{
    font-size: 26px;
  }
  .offer-modal .offer-modal-text p{
    padding: 0 10px;
    font-size: 14px;
    line-height: 25px;
  }
  .offer-modal .offer-modal-text h2{
     font-size: 30px;
  }
  .offer-modal .modal-center-img img{
    max-width: 300px;
    width: 300px;
  }
}

@media (max-width:567px){
   .offer-modal .modal-top-logo img{
    width:100px;
    height: auto;
    min-height: auto;
  }
  .offer-modal .offer-modal-text h3{
    font-size: 22px;
     margin: 10px 0;
  }
  .offer-modal .modal-center-img img{
    max-width: 250px;
    width: 250px;
    height: auto;
    min-height: auto;
  }
  .offer-modal .offer-modal-text p{
    line-height: 20px;
  }
  .offer-modal .footer{
    font-size: 14px;
    margin-bottom: 8px;
  }
}
@media (max-width:400px){
.offer-modal .offer-modal-text h3{
  font-size: 18px;
}
}
@media (max-width:300px){
  .offer-modal .offer-modal-text h3 {
    font-size: 16px;
     margin: 2px 0;
  }
  .offer-modal .offer-modal-text h2{
    font-size: 16px;
    margin: 10px 0;
  }
  .offer-modal .modal-center-img img{
    max-width: 220px;
    width: 220px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
   .offer-modal .offer-modal-text h3{
    font-size: 42px;
    margin: 10px 0;
   }
   .offer-modal .modal-top-logo img{
    height: 120px;
    min-height: 120px;
   }
   .offer-modal .footer{
     margin-bottom:5px;
   }
}
</style>
