<template>
  <div class="accordion" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
    <button
      @click="toggleAccordion()"
      class="accordion__header"
      :aria-expanded="isOpen"
      itemprop="name"
    >
      <slot name="title" />
    </button>
    <div class="accordion__content" v-show="isOpen" itemscope itemprop="acceptedAnswer">
      <div itemprop="text">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleAccordion (state) {
      if (state) {
        this.isOpen = state
      } else {
        this.isOpen = !this.isOpen
      }
    }
  }
}
</script>
