<template>
  <!-- main part -->
  <main>
    <section class="banner pt-100" style="padding-top: 144px">
      <div class="container-fluid">
        <div class="row-col">
          <div class="imageLeft">
            <h1>Your personal furniture shopping service</h1>
            <!--                  <h6 class="subtitle">Let us do it for you.</h6>-->
            <p class="homeDescription">We determine your needs and preferences.
              Then our experts find furniture that’s just right for you.
            </p>
            <p class="my-4">Easy as one, toTree.</p>
            <router-link type="button" class="btn-yello surveyCTA" to="/quiz" data-evtId="quiz-start">Find my Furniture
              <span class="icon-double-arrow"></span></router-link>
          </div>
          <div class="imageRIght">
            <figure>
              <CloudinaryImage public-id="ToTree/webapp/static/homepage-hero3"/>
            </figure>
          </div>
        </div>
      </div>
    </section>
    <section class="benifitset">
      <div class="container-fluid flexColumn">
        <h3 class="howItWorksTitle">How it Works</h3>
        <div class="howItWorksWrapper">
          <div class="howItWorksSubsectionWrapper">
            <p class="howItWorksSubsectionNumber" style="text-align: center">1.</p>
            <h6 class="howItWorksSubsectionTitle">Take our Style Survey</h6>
            <p class="howItWorksSubsectionContent">Answer some simple questions, upload a basic floor plan and photos,
              and tell us any important details about your budget, timetable, or style.</p>
            <div class="hideOnDesktop">
              <CloudinaryImage public-id="ToTree/webapp/static/how-it-works-1"/>
            </div>
          </div>
          <div>
            <p class="howItWorksSubsectionNumber" style="text-align: center">2.</p>
            <h6 class="howItWorksSubsectionTitle">Sit back and relax while our experts find your furniture</h6>
            <p class="howItWorksSubsectionContent">Based on your Style Survey, our specialists will find just the right
              living room, bedroom or dining room furniture for you. All sourced from our trusted brands.</p>
            <div class="hideOnDesktop">
              <CloudinaryImage public-id="ToTree/webapp/static/how-it-works-2"/>
            </div>
          </div>
          <div>
            <p class="howItWorksSubsectionNumber" style="text-align: center">3.</p>
            <h6 class="howItWorksSubsectionTitle"> Pick your favorites & order them with one click</h6>
            <p class="howItWorksSubsectionContent">Within 72 hours you'll get a pair of Style Boards with your custom
              furniture options. Use our Mix & Match feature to find a perfect combo and order with just one click!</p>
            <div class="hideOnDesktop">
              <CloudinaryImage public-id="ToTree/webapp/static/how-it-works-3"/>
            </div>

          </div>
          <div class="hideOnMobile">
            <CloudinaryImage public-id="ToTree/webapp/static/how-it-works-1"/>
          </div>
          <div class="hideOnMobile">
            <CloudinaryImage public-id="ToTree/webapp/static/how-it-works-2"/>
          </div>
          <div class="hideOnMobile">
            <CloudinaryImage public-id="ToTree/webapp/static/how-it-works-3"/>
          </div>
        </div>
        <div class="howItWorksVideoWrapper">
          <iframe class="howItWorksVideo" src="https://player.vimeo.com/video/954100005?h=40910b9975"
                  title="How it works video" allowfullscreen></iframe>
        </div>
        <div class="benifit">
          <div class="benefitsList">
            <div class="howItWorksCardsTitleSection">
              <!--                  <div class="title"><img-->
              <!--                    src="@/assets/images/sun.svg"-->
              <!--                  /> <h3 >Summer Steal</h3></div>-->
              <p class="title">Your $149 service fee will be applied to any furniture purchase.</p>
              <p class="text">One simple, straight forward flat rate:</p>
              <p class="regular"><b class="bold">$149 </b>/ per home</p>
            </div>
            <ul>
              <li>
                <figure><img src="@/assets/theme/images/be-1.svg"></figure>
                <div>
                  <p><strong>Expert support and guidance</strong></p>
                  <p>Every toTree client gets their own dedicated specialist throughout the entire process</p>
                </div>
              </li>
              <li class="list-group-item">
                <figure><img src="@/assets/theme/images/be-5.svg"></figure>
                <div>
                  <p><strong>Insta-Order feature</strong></p>
                  <p>With Insta-Order, all of your furniture options will be in-stock and ready to ship</p>
                </div>
              </li>
              <li>
                <figure><img src="@/assets/theme/images/be-3.svg"></figure>
                <div>
                  <p><strong>Returns and exchanges</strong></p>
                  <p>We'll handle them all for you</p>
                </div>
              </li>
              <li>
                <figure><img src="@/assets/theme/images/be-4.svg"></figure>
                <div>
                  <p><strong>Flat-rate shipping</strong></p>
                  <p>You’ll never worry about high shipping costs</p>
                </div>
              </li>
              <li>
                <figure><img src="@/assets/theme/images/be-2.svg"></figure>
                <div>
                  <p><strong>Mix & Match feature</strong></p>
                  <p>Easily create combos of your favorite furniture options (from brands including <i>Room & Board,
                    West Elm, Article, Burrow, Blu Dot, EQ3 and Sabai</i>) to see which works best</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <router-link type="button" class="btn-yello surveyCTA" to="/quiz" data-evtId="quiz-start">Find my Furniture
          <span class="icon-double-arrow"></span></router-link>
      </div>

    </section>
    <section class="explorationSection">
      <div class="explorationBanner">Come explore. Get inspired.</div>
      <div class="explorationImagesWrapper">
        <router-link to="/inspiration-gallery?room=Living Room" class="explorationImage">
          <h6>Living Room</h6>
          <CloudinaryImage :public-id="inspirationGalleryPhotos?.[0]"/>
        </router-link>
        <router-link to="/inspiration-gallery?room=Dining Room" class="explorationImage">
          <h6>Dining Room</h6>
          <CloudinaryImage :public-id="inspirationGalleryPhotos?.[1]"/>
        </router-link>
        <router-link to="/inspiration-gallery?room=Bedroom" class="explorationImage">
          <h6>Bedroom</h6>
          <CloudinaryImage :public-id="inspirationGalleryPhotos?.[2]"/>
        </router-link>
      </div>
    </section>
    <section>
      <div class="featured" style="margin-top: 0">
        <div class="container-fluid">
          <h6>As featured in</h6>
          <ul>
            <li><img src="@/assets/theme/images/maxim.png"></li>
            <li><img src="@/assets/theme/images/dwell.png"></li>
            <li><img src="@/assets/theme/images/tech-venture.png"></li>
            <li><img src="@/assets/theme/images/canvas-rebel.png"></li>
          </ul>
        </div>
      </div>
    </section>

    <section class="testimonialSet">
      <div class="container-fluid testimonial">
        <TestimonialList/>
      </div>
    </section>

    <section>
      <div class="featured">
        <div class="container-fluid furnitureBrands">
          <h6>Furniture brands</h6>
          <ul>
            <li><img src="@/assets/images/brands/home-page/bludot.png"></li>
            <li><img src="@/assets/images/brands/home-page/sabai.png"></li>
            <li><img src="@/assets/images/brands/home-page/benchmade.png"></li>
            <li><img src="@/assets/images/brands/home-page/surya.png"></li>
            <li><img src="@/assets/images/brands/home-page/eq3.png"></li>
            <li><img src="@/assets/images/brands/home-page/burrow.png"></li>
            <li><img src="@/assets/images/brands/home-page/westelm.png"></li>
            <li><img src="@/assets/images/brands/home-page/thuma.png"></li>
            <li><img src="@/assets/images/brands/home-page/roomandboard.png"></li>
            <li><img src="@/assets/images/brands/home-page/article.png"></li>
          </ul>
        </div>
      </div>
    </section>

    <section class="Frequently-asked">
      <div class="freLeft">
        <img src="@/assets/theme/images/faq-banner.jpg">
      </div>
      <div class="freRight">
        <h6>Frequently asked</h6>
        <accordion ref="accordion-0" @click="accordionClicked(0)">
          <template v-slot:title>
            <span class="icon-double-arrow"></span> Is toTree an interior design company?
          </template>
          <template v-slot:content>
            <p>Nope. We’re a furniture-shopping service (the first-ever). But we do provide the expert guidance and
              service of an interior designer – sans the cost - with a fast and easy online process. In short, we save
              folks time and money by finding the right furniture for them.</p>
          </template>
        </accordion>
        <accordion ref="accordion-1" @click="accordionClicked(1)">
          <template v-slot:title>
            <span class="icon-double-arrow"></span> How does the process work?
          </template>
          <template v-slot:content>
            <p>Simply take our Style Survey in which you’ll a) answer a few questions about your needs, tastes, and
              budget and b) submit a floorplan & photos of your room. Within 72 hours, we’ll send you personalized
              furniture options. Select what you like and order with one click!</p>
          </template>
        </accordion>
        <accordion ref="accordion-2" @click="accordionClicked(2)">
          <template v-slot:title>
            <span class="icon-double-arrow"></span> What sort of furniture do you offer?
          </template>
          <template v-slot:content>
            <p>To keep the process streamlined, we offer “foundational” furniture for living rooms, bedrooms, and dining
              rooms in three timeless styles: mid-century modern, California Coastal and industrial.</p>
            <p>1. Living room: sofas, chairs, sideboards, coffee tables, end tables, benches, ottomans, rugs</p>
            <p>2. Bedroom: beds, dressers, nightstands, rugs, benches, chairs</p>
            <p>3. Dining room: tables, chairs, stools, rugs, sideboards</p>
          </template>
        </accordion>
        <accordion ref="accordion-3" @click="accordionClicked(3)">
          <template v-slot:title>
            <span class="icon-double-arrow"></span> How much does toTree charge for their service?
          </template>
          <template v-slot:content>
            <p>Our service fee is $149 and valid for one year. Our fee includes furnishing up to four rooms, so if you
              buy living room furniture today and want to wait a few months to get your bedroom furniture, you won’t
              have to pay another service fee!</p>
          </template>
        </accordion>
        <accordion ref="accordion-4" @click="accordionClicked(4)">
          <template v-slot:title>
            <span class="icon-double-arrow"></span> What additional benefits do I get for my $149 service fee?
          </template>
          <template v-slot:content>
            <p>
              Additional benefits include:
            </p>
            <br>
            <p>
              Your own dedicated specialist throughout the process to answer any/all questions
            </p>
            <br>
            <p>
              We handle returns/exchanges
            </p>
            <br>
            <p>
              Flat rate shipping
            </p>
            <br>
            <p>
              Our Insta-Order feature: select this option and all your furniture selections will be in-stock and ready
              to ship.
            </p>
            <br>
            <p>
              A layout suggestion for your new furniture
            </p>
            <br>
            <p>
              Service fee is valid for one year - and up to four rooms!
            </p>
          </template>
        </accordion>
        <accordion ref="accordion-5" @click="accordionClicked(5)">
          <template v-slot:title>
            <span class="icon-double-arrow"></span> Can I apply my $149 service fee to the cost of my furniture?
          </template>
          <template v-slot:content>
            <p>YES! Our $149 fee is applied to any purchase of furniture. (One time use only)</p>
          </template>
        </accordion>
        <accordion ref="accordion-6" @click="accordionClicked(6)">
          <template v-slot:title>
            <span class="icon-double-arrow"></span> Is my dedicated specialist a “real” person?
          </template>
          <template v-slot:content>
            <p>Sure is. And it’s a person with extensive home furnishings expertise who’s available throughout the
              process to help. Like having your own furniture concierge!</p>
          </template>
        </accordion>
        <accordion ref="accordion-7" @click="accordionClicked(7)">
          <template v-slot:title>
            <span class="icon-double-arrow"></span> What furniture brands do you offer?
          </template>
          <template v-slot:content>
            <p>We offer 10 trusted, quality brands: West Elm, Room & Board, Article, Burrow, EQ3, Blu Dot, Sabai, Thuma.
              Surya, and BenchMade Modern.</p>
          </template>
        </accordion>
        <accordion ref="accordion-8" class="last-accordian" @click="accordionClicked(8)">
          <template v-slot:title>
            <span class="icon-double-arrow"></span> What should I expect to pay for furniture?
          </template>
          <template v-slot:content>
            <p>Quality furniture - well-made, long-lasting, and better for the environment – is somewhat pricier than
              the cheap, popular “disposable” furniture that cannot be recycled. Prices also depend on a variety of
              factors: size, materials, construction, etc. But we’re happy to try and work with anyone’s budget!</p>
          </template>
        </accordion>
        <router-link class="btn-Border" to="/support">Learn more</router-link>
      </div>
    </section>
  </main>
</template>

<script>

import { defineComponent } from 'vue'
// import 'owl.carousel/dist/owl.carousel.min.js';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
// eslint-disable-next-line no-unused-vars
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'
import Accordion from '@/components/Accordion'
import 'flickity/dist/flickity.min.css'
import 'flickity-fade/flickity-fade.css'
import TestimonialList from '@/components/TestimonialList.vue'
// import Quotes from '@/components/Quotes.vue'
// import Press from '@/components/Press.vue'
import { useRoute } from 'vue-router'
import { signInWithEmailAndPassword } from 'firebase/auth'
import CloudinaryImage from '@/components/CloudinaryImage.vue'
import { httpsCallable } from 'firebase/functions'

export default defineComponent({
  name: 'Home',
  data () {
    const route = useRoute()
    //
    if (route?.query?.login) {
      var decodedStr = Base64.decode(route?.query?.login)
      const socialData = JSON.parse(decodedStr)

      return {
        showPressSection: true,
        accordianStatus: {
          current: undefined,
          previous: undefined
        },
        socialData: socialData,
        inspirationGalleryPhotos: []
      }
    } else {
      return {
        showPressSection: true,
        accordianStatus: {
          current: undefined,
          previous: undefined
        },
        inspirationGalleryPhotos: []

      }
    }
  },
  components: {
    CloudinaryImage,
    Accordion,
    TestimonialList
    // Quotes,
    // Press
  },
  computed: {
    ...mapState(['firebaseAuth', 'firestore', 'selectionServiceFee', 'fbFunctions']),
    ...mapGetters(['currentUser', 'userProfile'])
  },
  async mounted () {
    const route = useRoute()
    Fancybox.bind('[data-fancybox]', {
      closeButton: 'outside',
      template: {
        closeButton: 'close'
      }
    })
    // Time zone check for popup show
    const timeZone = 'America/New_York'
    const currentDate = new Date().toLocaleString('en-US', { timeZone })
    //
    const currentTimestamp = new Date(currentDate).getTime() / 1000

    if (currentTimestamp <= 1704088799) {
      // this.setModalOffer(true)
    } else {

    }

    if (route?.query?.social === 'exists') {
      this.setModalOffer(false)
    }
    if (this?.socialData?.id) {
      this.setModalOffer(false)
      await signInWithEmailAndPassword(this.firebaseAuth, this.socialData.email, this.socialData.id)

      await this.userAuthCheck()
      if (this?.socialData?.id) {
        this.$router.push('/projects')
      }
    }
    const res = await httpsCallable(this.fbFunctions, 'listAllAssetsFromFolderFromCloudinary')({ path: 'ToTree/webapp/exploration' })
    this.inspirationGalleryPhotos = res.data?.assets ?? []
  },
  methods: {
    ...mapActions([
      'userAuthCheck'
    ]),
    ...mapMutations(['setModalAdditionalInfo', 'setModalOffer', 'setModalSignup']),
    clearTimeouts () {
      let id = setTimeout(() => {
      }, 0)
      while (id--) clearTimeout(id)
    },
    showModalAdditionalInfo () {
      this.setModalAdditionalInfo(true)
    },
    showModalOffer () {
      this.setModalOffer(true)
    },
    accordionClicked (index) {
      let counter = 0
      for (const i in this.$refs) {
        const targetAccordion = this.$refs[i]
        if (targetAccordion) {
          if (counter !== index) targetAccordion.isOpen = false
        }
        counter++
      }
    }
  },

  // Before we unmount this component, we need to clear the timeouts,
  // so they're no longer referencing elements that don't exist in the DOM.
  unmounted () {
    this.clearTimeouts()
  }
})
</script>

<style lang="scss">
/* Add your styles for the carousel container if needed */
.vue-slick-carousel {
  width: 80%;
  margin: 0 auto;
}

.h2_font_size {
  margin: 0;
  font-size: 20px;
  color: #000;
  font-family: "test_shnekrftig";
  margin-bottom: 20px;
}

.img-edit-btn {
  position: relative !important;
  /*padding-top: 20px !important;*/
}

.img-edit-btn .blink.up-img {
  position: absolute;
  top: -30px;
  left: -1px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.howItWorksWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 11.5vw;
  gap: 40px;
  padding: 0 200px;
  margin-bottom: 40px;
  // xd
  overflow: hidden;

  & .hideOnDesktop {
    display: none;
  }

  & .hideOnMobile {
    display: block;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    padding: 0 20px;
    gap: 0em;
    & .hideOnMobile {
      display: none;
    }
    & .hideOnDesktop {
      margin-top: 16px;
      display: block;
    }
  }
}

.explorationSection {
  background-color: #ECF9F3;

}

.howItWorksTitle {
  font-family: 'League Spartan', sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 27.6px;
  text-align: center;
  color: $tt-brand-green;
  padding: 40px;
  @media (max-width: 1300px) {
    padding: 10px;
  }
}

.howItWorksSubsectionNumber {
  font-family: 'League Spartan', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  color: $tt-brand-green;
  margin-bottom: 16px;
  @media (max-width: 1300px) {
    margin-top: 16px;
  }
}

.howItWorksSubsectionTitle {
  font-family: 'League Spartan', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  color: $tt-brand-green;
  margin-bottom: 16px;
  min-height: 3lh;
  @media (max-width: 1300px) {
    font-size: 18px;
    margin-bottom: 8px;
    min-height: 0;
  }
}

.howItWorksSubsectionContent {
  font-family: 'Sohne', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.84px;
  color: #0F172B;
}

.howItWorksVideoWrapper {
  padding: 40px;
  background: #ECF9F3;
  border-radius: 16px;
  width: fit-content;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    padding: 10px;
  }
}

.howItWorksVideo {
  width: 60vw;
  height: 30vw;
  @media (max-width: 768px) {
    width: 90vw;
    height: 50vw;
  }
}

.howItWorksCardsTitleSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #0F172B !important;

  & .title {
    font-family: "Sohne", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 25.48px;
    margin-bottom: 8px;
    color: #0F172B !important;
    display: flex;
    gap: 8px;
    text-align: center;
    @media(min-width: 1024px) {
      white-space: nowrap;
    }
  }

  & .text {
    font-family: "Sohne", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25.48px;
    text-align: center;
    margin-bottom: 24px;
  }

  & .bold {
    font-family: "Sohne", serif;
    font-size: 60px;
    font-weight: 700;
    line-height: 76.44px;
  }

  & .regular {
    font-family: "Sohne", serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25.48px;
    text-align: center;
    margin-bottom: 32px;
  }
}

.benifit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-edit-btn .blink.down-img {
  position: absolute;
  bottom: -30px;
  left: -1px;
  /* Safari */
  -webkit-transform: rotate(-180deg);

  /* Firefox */
  -moz-transform: rotate(-180deg);

  /* IE */
  -ms-transform: rotate(-180deg);

  /* Opera */
  -o-transform: rotate(-180deg);
}

.img-blink {
  width: 100px;
  height: 100px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.home-intro {
  height: calc(80vh - var(--site-header-height));
  min-height: 14rem;
  overflow: hidden;

  &__hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 80vh;
    background-image: url('../assets/images/home/home-hero.jpg');
    background-size: cover;
    background-position: center center;

    &__overlay {
      @include fill-parent;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .home-intro__cta {
    min-width: 300px;

    @include breakpoint($s) {
      min-width: 200px;
    }
  }

  &__content {
    position: absolute;
    top: calc(50% - 24vh);
    right: var(--outer-margin-x);
    left: var(--outer-margin-x);
    transform: translateY(-50%);
    text-align: center;

    .home-intro__header__tagline {
      @include h1;
      font-size: 26px !important;
      color: $tt-bright-white;
      line-height: var(--leading-h1);
      text-align: center;
      margin-bottom: calc(var(--gutter) * 2);

      .down {
        vertical-align: -.28em;
        font-size: 160%;
        line-height: 0;
        margin-right: -.02em;
        margin-left: -.02em;
      }

      .up {
        vertical-align: .15em;
        font-size: 85%;
        line-height: 0;
        margin-right: .02em;
      }

      .caps {
        letter-spacing: -.03em;
      }

      @include breakpoint($s) {
        font-size: 3.25rem !important;
        line-height: var(--leading-h1);
      }

      @include breakpoint($m-up) {
        padding: 0 12.5%;
      }

      @include breakpoint($xl) {
        padding: 0 4rem;
      }
    }
  }

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
  }

  .h1_font_size {
    font-size: 28px !important
  }

  .home-intro__nav {
    text-align: center;

    .home-intro__nav__item {
      //white-space: nowrap;
      margin-bottom: calc(var(--leading) / 2);

      a {
        display: inline-flex;
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 .75em;
      }

      &.home-intro__nav__item--video a {
        @include h3;
        @include filter-white;
        color: $tt-bright-white;
        text-decoration: underline;
        padding-left: 1.75em;
        background-image: url('../assets/images/video.svg');
      }
    }
  }
}

.home__heading {
  grid-column: span 12;
  @include h1;
  margin-bottom: calc(var(--leading-h1) * .667);

  img {
    vertical-align: middle;
  }
}

.home__heading.sub {
  font-family: 'GT America Regular';
  font-weight: 400;
  font-size: 1.25rem;
  line-height: var(--leading);
  letter-spacing: 0.03em;

  @include breakpoint($s) {
    font-size: 1rem;
  }
}

.home__section {
  position: relative;
  z-index: 300;
  margin-bottom: var(--spacing-l);

  &__left,
  &__right {
    grid-column: span 6;
  }

  &.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
}

.home-process {
  background: $secondary-bg;
  padding: calc(var(--leading) * 2) 0 calc(var(--leading) * 5);
  position: relative;

  @include breakpoint($s) {
    margin-right: var(--outer-margin-x-negative);
    margin-left: var(--outer-margin-x-negative);
  }

  &.home__section {
    margin-bottom: var(--leading);
  }

  .home-process__content {
    display: flex;
    gap: calc(var(--gutter) * 4);

    @include breakpoint($s) {
      flex-direction: column;
    }
  }

  .home-process__heading {
    font-size: 3rem;

    @include breakpoint($s) {
      font-size: 1.75em;
      padding: 0 1em
    }
  }

  .home-process__subheading {
    @include h2;
    grid-column: span 12;
    text-align: center;
    font-family: 'GT America Regular';
    max-width: 600px;
    margin: var(--gutter) auto var(--leading) auto;

    @include breakpoint($s) {
      padding: 1em
    }
  }

  .home-process__step-titles {
    text-transform: uppercase;
    font-size: 1.25em;
  }

  .home-process__steps.left {
    flex: 1;
  }

  .home-process__steps.right {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .home-process__video {
      aspect-ratio: 1264 / 711;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .home-process__step {
    display: flex;

    .home-process__step__header, .home-process__step__text {
      flex: 1;
    }

    .home-process__step__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .home-process__step__header__number {
      text-align: center;
      @include meta-heading;
      margin-bottom: calc(var(--leading) / 2);

      span {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background: $brand-secondary;
        border-radius: 100%;
        padding-top: .8em;

        @include breakpoint($m-up) {
          padding-top: .6em;
        }
      }
    }

    .home-process__step__header__heading {
      @include h3;
      text-align: center;
      margin-bottom: var(--leading);
    }

    img {
      margin-bottom: calc(var(--leading) / 2);
    }

    @include breakpoint($s) {
      img {
        width: 50%;
        margin: 0 auto;
        display: block;
      }
    }

  }

  .home-process__cta {
    position: absolute;
    bottom: 2.5rem;
    left: calc(100% / 3);
    width: calc(100% / 3);
    color: white;
    border: none;
    font-size: 1em;

    @include breakpoint($s) {
      font-size: 0.75em;
      width: 50%;
      left: 25%;
    }
  }
}

.home-description {
  background: $tt-classic-white;
  padding: calc(var(--gutter) * 3) var(--gutter);

  &.home__section {
    margin-top: calc(var(--gutter-negative) * 2);
    margin-bottom: calc(var(--leading-h1) * 2);
  }

  .home__heading.sub:not(:last-child) {
    margin-bottom: calc(var(--leading) * 3);
  }

  .home__heading.sub {
    @include breakpoint($m-down) {
      margin-left: calc(var(--outer-margin-y-negative) * 2);
      margin-right: calc(var(--outer-margin-y-negative) * 2);
    }

    @include breakpoint($s) {
      font-size: 1.2rem;
      margin-left: calc(var(--gutter));
      margin-right: calc(var(--gutter));
      br {
        display: none;
      }
    }
  }
}

.home-additional-benefits {
  background: $tt-classic-white;
  padding: calc(var(--gutter) * 4) 0;

  .home__heading.upper {
    color: $tt-grey-40;
    text-transform: uppercase;
    margin-bottom: calc(var(--leading));
  }

  .home__heading.sub.upper {
    margin-bottom: 0;
  }

  .home__heading.sub.content {
    font-size: 1rem;

    @include breakpoint($s) {
      br {
        display: none;
      }
    }
  }

  .home__heading.sub.content:not(:last-child) {
    margin-bottom: calc(var(--leading-h1) * 2);
  }
}

.home-faq {
  background-color: $tt-gold;
  min-height: 780px;

  .container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 var(--gutter);

    @include breakpoint($m-down) {
      display: block;
    }
  }

  .home__heading, button {
    color: $tt-bright-white;
  }

  .home__section__left {
    @include breakpoint($m-down) {
      padding: calc(var(--gutter) * 2) var(--gutter) 0;
    }

    .image {
      position: absolute;
      left: 0;
      width: 50vw;
      height: 100%;
      background-image: url('../assets/images/home/bedroom.jpg');
      background-position: center center;
      background-size: cover;

      @include breakpoint($m-down) {
        position: static;
        height: 320px;
        width: 100%;
      }
    }
  }

  .home__section__right {
    padding: var(--spacing-l) var(--outer-margin-x) var(--gutter);

    @include breakpoint($m-down) {
      padding: calc(var(--gutter) * 2) var(--outer-margin-x);
      min-height: 320px;
    }
  }

  &__who-we-are {
    display: none;
  }

  &__learn-more {
    margin-top: var(--leading);

    a {
      font-family: 'GT Pressura Mono';
      color: $tt-bright-white;
    }
  }

  .text {
    grid-column: span 12;
    color: $tt-bright-white;

    @include breakpoint($m-up) {
      grid-column-start: 4;
      grid-column-end: 10;
    }

    .accordion__header {
      &::after {
        @include filter-white; // TODO: Use different asset rather than filtering to white
      }
    }
  }
}

.home-selections {
  background-color: $tt-classic-white;
  padding: calc(var(--gutter) * 4) 0;

  .home__heading {
    margin-bottom: calc(var(--leading-h1));
  }

  .home__heading.sub {
    color: $tt-grey-40;

    @include breakpoint($m-down) {
      margin-left: calc(var(--leading-h1));
      margin-right: calc(var(--leading-h1));
      br {
        display: none;
      }
    }
  }

  .home__heading-container.same-bg {
    position: relative;

    .home__heading {
      margin: 0 auto calc(var(--leading-h1));;
    }

    .flat-rate {
      position: absolute;
      top: -2.5em;
      right: 1.8em;
      max-width: 140px;
      transform: rotateZ(12deg);
      z-index: 10;

      @include breakpoint($m) {
        top: -2em;
        right: 0;
        width: 15%;
      }

      @include breakpoint($s) {
        top: -4.3em;
        right: 1em;
        width: 15%
      }
    }
  }

  .video-container {
    position: relative;
    width: 80%;
    display: block;
    margin: 0 auto;

    @include breakpoint($s) {
      width: 100%;
    }

    .plant-foreground {
      position: absolute;
      bottom: -30%;
      right: -385px;
      width: 50%;
      @include breakpoint($m-down) {
        display: none;
      }
    }
  }

  .iframe-container {
    padding-bottom: calc(875 / 1325 * 100%); // Video aspect ratio
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.home-spaces {
  position: relative;
  margin-top: var(--spacing-l);
  padding: 0 var(--gutter) calc(var(--gutter) * 2);

  &__heading {
    margin-bottom: var(--leading);

    @include breakpoint($s) {
      img {
        max-width: 60px;
      }
    }
  }
}

.homeDescription {
  width: 60% !important;
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.surveyCTA {
  margin-top: 32px !important;
  font-size: 24px;
}

.home-quotes {
  background-color: $tt-classic-white;
  padding: calc(var(--leading) * 3);

  .home__heading {
    margin-bottom: var(--leading);
  }
}

.orders,
.boards,
.home__cta {
  grid-column: span 12;
}

.home__cta {
  text-align: center;
}

.fancybox__container {
  .carousel__button.is-close {
    top: var(--outer-margin-y);
    right: var(--outer-margin-x);
    border-radius: 0;
    padding: 0;
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    text-indent: -9999px;
    background: url('../assets/images/close.svg') center center no-repeat;
    background-size: 2rem auto;
  }
}

.same-bg {
  position: relative;
}

.same-bg .flat-rate {
  position: absolute;
  top: 1.5em;
  right: 1.8em;
  max-width: 140px;
  transform: rotateZ(12deg);
  z-index: 10;
}

.same-bg .flat-rate-left {
  position: absolute;
  top: 1.5em;
  left: 1.8em;
  max-width: 140px;
  transform: rotateZ(12deg);
  z-index: 10;
}

@media (max-width: 992px) {
  .same-bg .flat-rate {
    max-width: 60px !important;
    width: 60px;
    top: 0.5em;
    right: 0.8em;
  }
  .same-bg .flat-rate-left {
    max-width: 60px !important;
    width: 60px;
    top: 0.5em;
    right: 0.8em;
  }
}

@media (max-width: 768px) {
  .same-bg .flat-rate {
    max-width: 50px !important;
    width: 50px;
    top: 0.5em;
    right: 0.8em;
  }
  .same-bg .flat-rate-left {
    max-width: 50px !important;
    width: 50px;
    top: 0.5em;
    right: 0.8em;
  }
  .home-intro__content .home-intro__header__tagline {
    margin-top: 30px;
  }
  .img-edit-btn .blink.down-img {
    bottom: -20px;
  }
  .img-edit-btn .blink.up-img {
    top: -20px;
  }
}

.home__section.full-width {
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 500px) {
  .home-intro__content .home-intro__header__tagline {
    margin-bottom: 10px;
    font-size: 4rem !important;
  }
  .home-intro .home-intro__cta {
    margin-bottom: 35px;
  }
}

.furnitureBrands {
  padding: 0 1rem !important;
}

.explorationBanner {
  width: 100%;
  padding: 40px;
  text-align: center;
  background-color: #ECF9F3;
  font-family: League Spartan, sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 27.6px;
  color: #004021;
  @media (max-width: 1024px) {
    margin-top: 40px;
  }
}

.explorationImagesWrapper {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 40px;
}

.explorationImage {
  padding: 16px;
  max-width: 350px;
  border-radius: 8px;

  &:hover {
    background-color: white;
  }

  h6 {
    flex-grow: 1;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  font-family: Sohne, serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.48px;
  text-align: center;
  text-decoration: none !important;
}
</style>
