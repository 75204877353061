<template>
  <div class="form__measurements">
    <p class="form__note wall-a-b-copy">Believe it or not, differentiating the walls (A and B) is an important step, so please give a brief description of each (i.e. Wall A: “has large mirror” or Wall B: “has more windows”, etc). The more specific the better. Thank you!</p>
    <div class="form__content">
      <div class="measurement">
        <label for="wall-a-feet"><p>Wall A</p></label>
        <div class="feet-inches">
          <div class="form__item--text">
            <input
              type="number"
              min="0"
              name="wall-a-feet"
              id="wall-a-feet"
              placeholder="Feet"
              v-model="wallA.feet"
              @input="updateValue"
            />
          </div>
          <div class="form__item--text">
            <input
              type="number"
              min="0"
              max="11"
              name="wall-a-inches"
              id="wall-a-inches"
              placeholder="Inches"
              v-model="wallA.inches"
              @input="updateValue"
            />
          </div>
        </div>
        <label for="wall-a-description">
          <p>Describe wall A</p>
        </label>
        <textarea
          name="wall-a-description"
          id="wall-a-description"
          v-model="inputValue['wall-a-description']"
          @input="updateValue"
        />
        <p class="character-count">
          {{ wallACharCount }}
        </p>
      </div>
      <div class="measurement">
        <label for="wall-b-feet"><p>Wall B</p></label>
        <div class="feet-inches">
          <div class="form__item--text">
            <input
              type="number"
              min="0"
              name="wall-b-feet"
              placeholder="Feet"
              v-model="wallB.feet"
              @input="updateValue"
            />
          </div>
          <div class="form__item--text">
            <input
              type="number"
              min="0"
              max="11"
              name="wall-b-inches"
              placeholder="Inches"
              v-model="wallB.inches"
              @input="updateValue"
            />
          </div>
        </div>
        <label for="wall-b-description">
          <p>Describe wall B</p>
        </label>
        <textarea
          name="wall-b-description"
          id="wall-b-description"
          v-model="inputValue['wall-b-description']"
          @input="updateValue"
        />
        <p class="character-count">
          {{ wallBCharCount }}
        </p>
      </div>
      <div class="measurement">
        <label for="doorway-height-feet"><p>Door Height</p></label>
        <div class="feet-inches">
          <div class="form__item--text">
            <input
              type="number"
              min="0"
              name="doorway-height-feet"
              placeholder="Feet"
              v-model="door.height.feet"
              @input="updateValue"
            />
          </div>
          <div class="form__item--text">
            <input
              type="number"
              min="0"
              max="11"
              name="doorway-height-inches"
              placeholder="Inches"
              v-model="door.height.inches"
              @input="updateValue"
            />
          </div>
        </div>

        <label for="doorway-width-feet"><p>Door Width</p></label>
        <div class="feet-inches">
          <div class="form__item--text">
            <input
              type="number"
              min="0"
              name="doorway-width-feet"
              placeholder="Feet"
              v-model="door.width.feet"
              @input="updateValue"
            />
          </div>
          <div class="form__item--text">
            <input
              type="number"
              min="0"
              max="11"
              name="doorway-width-inches"
              placeholder="Inches"
              v-model="door.width.inches"
              @input="updateValue"
            />
          </div>
        </div>
      </div>
      <!-- <ValidationWarning
        :valid="valid"
        :text="'`All above fields are required. Please ensure that your descriptions are at least ${descriptionMinimumChars} characters long.`'"
      /> -->
      <ValidationWarning
        :valid="valid"
        text="All measurement fields above are required."
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    question: {
      type: Object,
      default: () => {}
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      descriptionMinimumChars: 0,
      wallA: {
        feet: null,
        inches: null
      },
      wallB: {
        feet: null,
        inches: null
      },
      door: {
        height: {
          feet: null,
          inches: null
        },
        width: {
          feet: null,
          inches: null
        }
      },
      inputValue: {},
      valid: true
    }
  },
  computed: {
    wallACharCount () {
      return this.inputValue['wall-a-description'] ? this.inputValue['wall-a-description'].length : 0
    },
    wallBCharCount () {
      return this.inputValue['wall-b-description'] ? this.inputValue['wall-b-description'].length : 0
    }
  },
  mounted () {
    if (this.modelValue) {
      if (this.modelValue['wall-a']) {
        const wallAParts = this.formatFtIn(this.modelValue['wall-a'])
        this.wallA = {
          feet: wallAParts[0],
          inches: wallAParts[1]
        }
      }
      if (this.modelValue['wall-b']) {
        const wallBParts = this.formatFtIn(this.modelValue['wall-b'])
        this.wallB = {
          feet: wallBParts[0],
          inches: wallBParts[1]
        }
      }
      if (this.modelValue['doorway-height']) {
        const doorwayHeightParts = this.formatFtIn(this.modelValue['doorway-height'])
        this.door.height = {
          feet: doorwayHeightParts[0],
          inches: doorwayHeightParts[1]
        }
      }
      if (this.modelValue['doorway-width']) {
        const doorwayWidthParts = this.formatFtIn(this.modelValue['doorway-width'])
        this.door.width = {
          feet: doorwayWidthParts[0],
          inches: doorwayWidthParts[1]
        }
      }
      this.inputValue['wall-a-description'] = this.modelValue['wall-a-description']
      this.inputValue['wall-b-description'] = this.modelValue['wall-b-description']
    }
  },
  methods: {
    formatFtIn (inputString) {
      inputString = inputString.replace('ft', '')
      inputString = inputString.replace('in', '')
      return inputString.split(' ')
    },
    updateValue (e) {
      const maxInches = 11.9
      if (this.wallA.inches > maxInches) this.wallA.inches = maxInches
      if (this.wallB.inches > maxInches) this.wallB.inches = maxInches
      if (this.door.height.inches > maxInches) this.door.height.inches = maxInches
      if (this.door.width.inches > maxInches) this.door.width.inches = maxInches

      this.inputValue['wall-a'] = `${this.wallA.feet}ft ${this.wallA.inches}in`
      this.inputValue['wall-b'] = `${this.wallB.feet}ft ${this.wallB.inches}in`
      this.inputValue['doorway-height'] = `${this.door.height.feet}ft ${this.door.height.inches}in`
      this.inputValue['doorway-width'] = `${this.door.width.feet}ft ${this.door.width.inches}in`
      this.$emit('update:modelValue', this.inputValue)
    },

    validate () {
      let fieldsFilled = false
      let charCountMet = false
      if (this.required) {
        fieldsFilled = !!(
          this.wallA.feet && this.wallA.feet > 0.1 &&
          this.wallA.inches >= 0 &&
          this.wallB.feet && this.wallB.feet >= 0.1 &&
          this.wallB.inches >= 0 &&
          this.door.height.feet && this.door.height.feet >= 1 &&
          this.door.height.inches >= 0 &&
          this.door.width.feet && this.door.width.feet >= 0.1 &&
          this.door.width.inches >= 0
        )

        charCountMet = (
          this.wallACharCount >= this.descriptionMinimumChars &&
          this.wallBCharCount >= this.descriptionMinimumChars
        )
      }
      if (!this.required || (fieldsFilled && charCountMet)) {
        this.valid = true
      } else {
        this.valid = false
      }
      return this.valid
    }
  }
}
</script>

<style scoped lang="scss">
.wall-a-b-copy {
  margin-bottom: calc(var(--gutter) * 2);
}
.feet-inches {
  display: flex;
  gap: 4px;

  .form__item--text {
    flex: 1;
  }
}
.character-count {
  display: none;
  @include meta-text;
  font-size: .6rem !important;
}
</style>
