<template>
  <fieldset class="form__question">
    <div class="form__content">
      <legend class="quiz__subheading">{{ question.question }}</legend>
      <div class="form__item form__item--select">
        <select :id="question.slug">
          <option disabled selected>{{ question.prompt }}</option>
          <option v-for="(answer, index) in question.answers" :key="index">{{ answer.text }}</option>
        </select>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
