<template v-if="quizData">
  <transition name="fade" mode="out-in">
    <form class="form" v-on:submit.prevent="() => {}">
      <div class="grid__inner">
        <fieldset class='form__question new_form_style'>
          <div class="form__content">
            <div class="form__item form__item--text inputColumns">
              <label>First Name</label>
              <input
                type="text"
                autocomplete="given-name"
                placeholder="First name"
                v-model="inputValue['first_name']"
                @blur="checkInputValues('first_name')"
              >
            </div>
            <div class="form__item form__item--text inputColumns">
              <label>Email Address</label>
              <input
                type="email"
                autocomplete="email"
                placeholder="Email address"
                v-model="inputValue['email']"
                @blur="checkInputValues('email')"
              >
            </div>
            <div class="form__item form__item--text inputColumns show-password__reveal--text">
              <label>Secure Password</label>
              <input
                :type="this.showPassword ? 'text' : 'password'"
                autocomplete="new-password"
                placeholder="Password"
                v-model="inputValue['newPassword']"
              >
            </div>
            <div class="form__item form__item--text inputColumns show-password__reveal--text">
              <label>Repeat Password</label>
              <input
                :type="this.showPassword ? 'text' : 'password'"
                autocomplete="new-password"
                placeholder="Password (repeat)"
                v-model="inputValue['newPasswordAgain']"
                @blur="checkInputValues('password')"
              >
            </div>
            <div
              class="form__item form__item--checkbox inputColumns form__item--half custom_New_checkbox checkbox_for_password">
              <input
                type="checkbox"
                value="showPassword"
                name="show-password"
                id="show-password"
                v-model="showPassword"
              > Show password
            </div>
            <div class="signInButtonWrapper">
              <span class="signInText">Already have an account? </span>
              <button class="signInButton" @click="() => setModalSignup(true)">Sign in!</button>
            </div>
            <SocialAuth variant="register" />
            <transition name="warning-slide-down" mode="out-in">
              <div v-if="userErrorMessage" class="form-validation warning">
                <span class="closeicon"><i class="icon-close"></i></span>
                <div>
                  <h5>There was a problem with your submission.</h5>
                  <p class="errorsub"><span></span>
                    {{ userErrorMessage }}
                  </p>
                </div>
              </div>
            </transition>
          </div>
        </fieldset>
        <div class="quiz__navigation navigation-handle">
          <button
            @click="prevStep"
            class="quiz__navigation__button quiz__navigation__button--back"
          >
            <span class="icon-arrow-left"></span> Back
          </button>
          <div>
            <button
              @click="onNextStep"
              class="quiz__navigation__button quiz__navigation__button--continue primary"
            >
              Continue
              <span class="icon-arrow-right"></span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </transition>
</template>

<script>
/*eslint-disable*/
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {createUserWithEmailAndPassword} from 'firebase/auth'
import * as quizData from '@/components/quiz/quizDataNew'
import {validateEmailString} from '@/assets/scripts/helpers'
import SocialAuth from "@/components/socialAuth/SocialAuth.vue";

export default {
  name: 'Quiz Registration New',
  components: {SocialAuth},
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    nextStep: {
      type: Function
    },
    prevStep: {
      type: Function
    },
    setPageLoading: {
      type: Function
    }
  },
  data() {
    return {
      loading: false,
      quizData,
      inputValue: {},
      userErrorMessage: '',
      allFieldsValid: false,
      showPassword: false,
      signedIn: false
    }
  },

  computed: {
    ...mapState(['fbFunctions', 'firebaseAuth', 'firestore']),
    ...mapGetters([
      'currentUser'
    ])
  },

  methods: {
    ...mapMutations([
      'setModalSignup',
      'setGlobalError',
      'setModalSignup'
    ]),
    ...mapActions([
      'userAuthCheck',
      'initUserProfileData'
    ]),

    async onNextStep() {
      this.setPageLoading(true)

      const response = await this.signUpStep()
      if (response) {
        this.nextStep()
      }
      this.setPageLoading(false)
    },

    async checkInputValues(target) {
      const checkEmail = validateEmailString(this.inputValue.email)
      this.userErrorMessage = ''
      this.allFieldsValid = true
      if (target === 'password' || target === 'all') {
        if (!this.inputValue.newPassword || this.inputValue.newPassword.length < 6) {
          this.userErrorMessage = 'Passwords must be 6 characters or more.'
          this.allFieldsValid = false
        } else if (this.inputValue.newPassword !== this.inputValue.newPasswordAgain) {
          this.userErrorMessage = 'Passwords do not match.'
          this.allFieldsValid = false
        }
      }
      if (target === 'email' || target === 'all') {
        if (!checkEmail.isValid) {
          this.userErrorMessage = checkEmail.message
          this.allFieldsValid = false
        }
      }
      if (target === 'first_name' || target === 'all') {
        if (!this.inputValue.first_name) {
          this.userErrorMessage = 'A First Name is Required.'
          this.allFieldsValid = false
        }
      }
      return {status: this.allFieldsValid, message: this.userErrorMessage}
    },

    handleAccountCreationError(error) {
      this.allFieldsValid = false
      // error.code, error.message are returned from FirebaseAuth
      // see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_3 for error types.

      switch (error.code) {
        case 'auth/email-already-in-use':
          this.userErrorMessage = 'This email is already in use. Please Sign In Below.'
          this.allFieldsValid = false

          break
        case 'auth/invalid-email':
          this.userErrorMessage = 'This email address is invalid.'
          this.allFieldsValid = false
          break
        case 'auth/operation-not-allowed':
          this.userErrorMessage = 'Unexpected error. Please refresh the page and try again.'
          this.allFieldsValid = false
          break
        case 'auth/weak-password':
          this.allFieldsValid = false
          this.userErrorMessage = 'Password should be at least 6 characters.'
          break
        default:
          this.userErrorMessage = 'Unexpected error. Please refresh the page and try again.'
          this.allFieldsValid = false
          break
      }

      return false
    },

    /**
     * Create User New User account with email
     */
    async createUserAccount() {
      this.loading = true
      // TODO: Move most of this stuff to the back end so that it can all be handled in "bulk"

      // AUTH STEP 1 - Sign in with email / password
      // First, create the user account.
      try {
        const response = await createUserWithEmailAndPassword(this.firebaseAuth, this.inputValue.email, this.inputValue.newPassword)
        await this.userAuthCheck()
        response.user.displayName = this.inputValue.first_name
        await this.initUserProfileData(response)

      } catch (error) {
        this.loading = false
        this.handleAccountCreationError(error)
        return false
      }

      if (this.$gtm?.enabled()) {
        this.$gtm.trackEvent({
          event: 'Sign up',
          category: 'Sign up',
          action: 'click',
          label: 'basic sign up',
          value: this.inputValue.email,
        })
      }
      this.allFieldsValid = false
      this.userErrorMessage = null
      this.loading = false
      return true
    },

    async signUpStep() {
      await this.checkInputValues('all')
      // prevents next if not all fields are entered.
      if (!this.allFieldsValid) {
        return false
      }
      return await this.createUserAccount()
    },

    onSuccess(data) {
      this.signedIn = true
    },

    onSuccessLogout() {
      this.signedIn = false
    }
  },
  async mounted() {
    this.inputValue = this.modelValue || ''
  },
  watch: {
    currentUser(newVal) {
      if (newVal?.uid) {
        this.nextStep()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.quiz__container--full {
  display: grid;
  grid-column: span 12;

  p {
    margin-bottom: calc(var(--leading) * .667);
    text-align: center;
    font-size: 0.8rem;
  }

  a.underline {
    text-decoration: underline;
  }
}

.show-password__reveal--text {
  color: $tt-off-white;
}

.social-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

#appleid-signin svg text {
  display: none !important;
}

.social-btn > div {
  margin: 5px;
  width: auto;
}

.social-auth img {
  width: 45px;
}

div#appleid-signin {
  height: 48px;
  width: 48px;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
}

.social-work {
  display: block;
}

.social-work .quiz__navigation {
  position: relative !important;
}

.social-work .social-btn {
  margin-top: 2rem;
}

.g-social-btn {
  width: 45px;
  height: 45px;
  padding: .5rem;
}

.g-social-btn img {
  width: 20px;
}

.g-btn-wrapper {
  margin-top: 2rem;
}

button.login-button {
  background-color: white;
  height: 32px;
  width: 200px;
  border-radius: 5px;
  border: 0px;
  font-size: 12px;
  font-weight: 500;
  margin: 5px;
}

button.login-button svg {
  height: 15px;
  margin-top: -3px;
  margin-right: 3px;
}

.social-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

#appleid-signin svg text {
  display: none !important;
}

.social-btn > div {
  margin: 5px;
  width: auto;
}

.social-auth img {
  width: 45px;
}

div#appleid-signin {
  height: 48px;
  width: 48px;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
}

.signInButtonWrapper {
  grid-column: span 12;
}

.signInText {
  font-family: Sohne, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #0B1326;
}

.signInButton {
  padding: 0 !important;
  border: none !important;
  font-family: Sohne, serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: none !important;
  color: $tt-brand-green;

  &:hover {
    text-decoration: underline !important;
    background: transparent !important;
  }
}
</style>
