<template>
    <div class="promoBarWrapper">
      <p>SUMMER SPECIAL: Apply the code <b class="boldText">SUMMER2024</b> at checkout and get toTree’s services for <b class="boldText">FREE!</b></p>
    </div>
</template>

<script></script>

<style lang="scss">
.promoBarWrapper {
  width: 100%;
  padding: 0.75rem;
  text-align: center;
  background-color: #4c86a0;
  color: #fff;
  margin-top: 0.5rem;
  letter-spacing: .075lh;
}
.navbar {
  padding-bottom: 0 !important;
}

.boldText {
  font-weight: 500;
}
</style>
