<template>
  <fieldset class="form__question">
    <div class="form__content">
      <legend v-if="question" class="quiz__subheading">{{ question }}</legend>
      <div class="form__item form__item--select form__item--half">
        <select
          v-model="address.state"
          @input="updateValue"
          @blur="validateTarget('address.state')"
        >
          <option
            v-for="(state, index) in usStates"
            :value="state.abbreviation"
            :key="`state-${index}`"
            :disabled="!state.abbreviation"
          >
            {{ state.name }}
          </option>
        </select>
      </div>
      <div class="form__item form__item--text form__item--half">
        <input
          type="text"
          maxlength="12"
          pattern="\d*"
          placeholder="Shipping Zip Code"
          v-model="address.zip"
          @input="updateValue"
          @blur="validateTarget('address.zip')"
          class="hide-arrows"
        >
      </div>
      <ValidationWarning
        :valid="valid"
        :text="errorMessage"
      />

    </div>
  </fieldset>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    question: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: true,
      checkEmail: {},
      address: this.modelValue || {},
      errorMessage: ''
    }
  },
  computed: {
    ...mapState(['usStates']),
    ...mapGetters(['currentUser', 'userProfile', 'userFullName'])
  },
  created () {
    // On load, autofill with applicable information if available

    if (this.userProfile && !Object.keys(this.modelValue).length) {
      this.address = {
        ...this.address,
        ...{
          state: this.userProfile.shipping_state || null,
          zip: this.userProfile.shipping_zip || this.userProfile.shipping_zip || ''
        }
      }
      this.updateValue()
    }
  },
  methods: {
    updateValue () {
      this.$emit('update:modelValue', this.address)
    },
    validate () {
      // If any following are invalid, the entire module is invalid
      this.errorMessage = ''
      if (!this.validateTarget('address.state')) return false
      if (!this.validateTarget('address.zip')) return false
      this.valid = true
      return true // Once past all guards, return validity (validate() called from parent Quiz module)
    },

    /**
     * Validates individual fields in the address input as the user moves through items.
     */
    validateTarget (target) {
      this.valid = true

      if (target === 'address.state') {
        if (!this.address.state) {
          this.errorMessage = 'A shipping state is required.'
          this.valid = false
        }
      } else if (target === 'address.zip') {
        if (!this.address.zip) {
          this.errorMessage = 'A shipping zip code is required.'
          this.valid = false
        }
      }
      return this.valid
    }
  }
}
</script>
<style lang="scss" scoped>
.hide-arrows::-webkit-outer-spin-button,
.hide-arrows::-webkit-outer-spin-button:hover,
.hide-arrows::-webkit-inner-spin-button,
.hide-arrows::-webkit-inner-spin-button:hover {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
