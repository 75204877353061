<template>
  <Modal class="modelStyle loginMOdelNew" @close="closeModalSignup">
    <template v-slot:header>
      <transition name="fade" mode="out-in">
        <div>
        <h1 :key="modalTitle">{{ modalTitle }}</h1>
      </div>
      </transition>
      <button
          class="modal__close"
          type="button"
          aria-label="Close modal"
          @click="close()"
        >
          Close
        </button>
    </template>
    <template v-slot:body>
      <transition name="fade" mode="out-in">
        <form v-if="forgotPassword" class="form reset-password new_form_style">
          <div class="newLogin newLoginFOrgot">
          <div class="form__item form__item--text formitemINput">
            <label>Email address</label>
            <input
              v-model="email_address"
              name="email"
              id="email"
              type="email"
              placeholder="Email address"
            >
          </div>
          <button
            type="submit"
            class="customGreenBTN"
            @click.prevent="initiatePasswordReset"
          >
            Submit  &nbsp; <span class="icon-arrow-right"></span>
          </button>
          </div>
          <div class="warning" v-if="errorMessageForgot">{{errorMessageForgot}}</div>
        </form>
        <form v-else class="form login-form new_form_style">
          <div class="newLogin">
          <div class="form__item formitemINput form__item--text">
            <label>Email address</label>
            <input
              v-model="email_address"
              name="email"
              id="email"
              type="email"
              placeholder="Email address"
            >
          </div>
          <div class="form__item formitemINput">
          <div class="form__item--text">
            <label>Password</label>
            <input v-model="password" id="password" class="show-password__reveal--text" :type="this.showPassword ? 'text' : 'password'" placeholder="Password">
          </div>
          <div class="form__item--checkbox">
            <input
              type="checkbox"
              value="showPassword"
              name="show-password"
              class="show-password-toggle"
              v-model="showPassword"
            > Show password
          </div>
        </div>
          <div class="form__item form__item--submit">
            <button
              type="submit"
              class="customGreenBTN"
              @click.prevent="signInUserAccount"
            >
              Sign in &nbsp; <span class="icon-arrow-right"></span>
            </button>
          </div>
        </div>
          <div class="form__item form__item--warning">
            <div class="warning" v-if="errorMessage">
              <!-- <template v-if="errorMessage === 'user-not-found'">Looks like you don't have an account.<br>Take the <router-link to="/registration" @click="setModalSignup(false)">survey</router-link> and let us get to know each other!</template>
              <template v-else>
                {{ errorMessage }}
              </template> -->
              <span class="closeicon"><i class="icon-close"></i></span>
              <div>
                <h5>There was a problem with your submission.</h5>
                <p class="errorsub"><span></span>
                  {{ errorMessage }}
                </p>
              </div>
            </div>
          </div>
        </form>
      </transition>
      <SocialAuth :on-success="() => {console.log('succesfully signed in');closeModalSignup()}"/>
      <Loader :visible="loading" blocking/>
    </template>
    <template v-slot:footer>
      <div class="border-bottom"></div>
      <p class="footer" v-if="forgotPassword">Return to
        <span @click="forgotPassword = false">
          Sign in
        </span>
      </p>
      <div v-if="!forgotPassword" class="notAccount">
        <p>No account yet?  <span @click="showModalAdditionalInfo">Take the style survey</span> to get started.</p>
        <p><span @click="forgotPassword = true">Forgot your password?</span></p>
      </div>

      <div ref="myDiv"  style="display: none;" class="loader"></div>
    </template>
    <Loader :visible="loading"  />
  </Modal>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import { validateEmailString, validatePasswordString } from '@/assets/scripts/helpers'
import Modal from '@/components/Modal'
import Loader from '@/components/Loader'
import { ref } from 'vue'
import SocialAuth from '@/components/socialAuth/SocialAuth.vue'
const loading = ref(false)
export default {
  name: 'ModalSignup',
  components: {
    SocialAuth,
    Modal,
    Loader
    // SocialAuth
  },
  data () {
    return {
      email_address: '',
      password: '',
      errorMessage: null,
      errorMessageForgot: null,
      forgotPassword: false,
      loading: false,
      showPassword: false,
      userErrorMessage: '',
      allFieldsValid: false,
      signedIn: false
    }
  },
  computed: {
    ...mapState(['firebaseAuth', 'firestore', 'fbFunctions']),
    ...mapGetters(['currentUser', 'userProfile']),
    modalTitle () {
      return this.forgotPassword ? 'Forgot Password' : 'Sign in to your account'
    }
  },

  methods: {
    ...mapActions([
      'userAuthCheck'
    ]),

    ...mapMutations([
      'setModalSignup',
      'setGlobalNotification',
      'setModalAdditionalInfo'
    ]),
    close () {
      this.$emit('closeModalSignupEvent')
    },
    closeModalSignup () {
      // This is emitted to the parent.
      setTimeout(() => {
        this.forgotPassword = false
      }, 300)
      this.$emit('closeModalSignupEvent')
    },
    showModalAdditionalInfo () {
      this.setModalAdditionalInfo(true)
      this.$emit('closeModalSignupEvent')
    },
    async signInUserAccount () {
      // validating email format. signInWithEmailAndPassword is not specifying an invalid email format error.
      const checkEmail = validateEmailString(this.email_address)
      if (!checkEmail.isValid) {
        this.errorMessage = 'This email address is invalid.'
        return false
      }

      // validating password format. signInWithEmailAndPassword is not specifying missing or short password error.
      const checkPass = validatePasswordString(this.password)
      if (!checkPass.isValid) {
        this.errorMessage = checkPass.message
        return false
      }

      try {
        const response = await signInWithEmailAndPassword(this.firebaseAuth, this.email_address, this.password)
        //
        //
        //
        localStorage.setItem('uid', response?.user.uid)
        await this.userAuthCheck()

        // if (this.currentUser.email) {
        //   this.$router.push('/projects')
        // }
        //
        this.setModalSignup(false)
      } catch (error) {
        // error.code, error.message are returned from FirebaseAuth
        // see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithemailandpassword for error types.

        switch (error.code) {
          case 'auth/missing-email':
            this.errorMessage = 'Please enter an email addresss.'
            break
          case 'auth/invalid-email':
            this.errorMessage = 'This email address is invalid.'
            break
          case 'auth/user-disabled':
            this.errorMessage = 'This account has been disabled.'
            break
          case 'auth/user-not-found':
            this.errorMessage = 'user-not-found'
            break
          case 'auth/wrong-password':
            this.errorMessage = 'The password does not match the account.'
            break
          case 'auth/network-request-failed':
            this.errorMessage = 'A network error has occurred. Please try again later.'
            break
          default:
            this.errorMessage = 'An unknown login error has occurred. ' + error
            break
        }
      }
    },

    /**
     * Note that, in the local emulators, the email won't be sent.
     * Rather, it'll appear in the emulator console as a link to modify and visit.
     */
    async initiatePasswordReset () {
      this.errorMessageForgot = null
      loading.value = true
      // this.loading = true
      try {
        // await sendPasswordResetEmail(this.firebaseAuth, this.email_address)
        await httpsCallable(this.fbFunctions, 'sendgrid-passwordResetEmail')({
          to: this.email_address
        })

        loading.value = false
        // this.loading = false
        this.closeModalSignup()
        this.setGlobalNotification('Email Sent')
      } catch (err) {
        // this.loading = false
        loading.value = false

        this.errorMessageForgot = err.message
      }
    },
    onSuccess (data) {
      this.signedIn = true
    },
    onSuccessLogout () {
      this.signedIn = false
    },
    onFailure (error) {
      console.error(error)
    }
  },
  mounted () {
    // this.loading = true
  }
}
</script>
<style scoped lang="scss">
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0 30px $tt-walnut inset !important;
  transition: background-color 5000s ease-in-out 0s; // hack to get background color to go transparent see background-color
  background-color: transparent !important;
  background-image: none !important;
}
.show-password__reveal--text {
  color: white
}
input[type=email]:hover,
input[type=email]:active,
input[type=email]:focus {
  background: $tt-green;
}
#email {
  background: $tt-green;
}
#password:hover,
#password:active,
#password:focus {
  color: white;
  background: transparent;
}
#password {
  color: white;
  &:focus {
    color: white;
    background: transparent;
  }
}
#password::placeholder {
  color: rgba(255, 255, 255, .7);
}

input.show-password__reveal--text {
  color: black;

  @include placeholder-color($tt-grey-20);
  &:focus {
      background: white;
      color: black
    }
}
.form__item--warning {
  clear: both;
}
.show-password-toggle {
  color: white;
}
.form__item--checkbox {
  display: inline;
  float: left;
}
.form__item--submit {
  clear: both;
}
.footer {
  .underline {
    cursor: pointer;
    text-decoration: underline;
  }
}
button[type="submit"] {
  margin-top: 2rem;
}
.g-social-btn{
  width:50px;
  height:50px;
  padding:.5rem;
  min-width: auto !important;
}
.g-social-btn img{ width: 20px;}
.g-btn-wrapper {
  margin-top:2rem;
}
button.login-button {
  background-color: white;
  height: 32px;
  width: 200px;
  border-radius: 5px;
  border: 0px;
  font-size: 12px;
  font-weight: 500;
  margin: 5px;
}
button.login-button svg{
  height: 15px;
  margin-top: -3px;
  margin-right: 3px;
}

.social-btn{
  display:flex;
  align-items: center;
  justify-content: center;
}

#appleid-signin svg text {
  display:none !important;
}

.social-btn > div {
    margin: 5px;
    width:auto;
}

.social-auth img {
  width:45px;
}

div#appleid-signin {
    height: 48px;
    width: 48px;
    border-radius: 50px;
    overflow: hidden;
    cursor: pointer;
}
.border-bottom
{
  border-top: 1px solid #DDDDDD;
  padding: 5px 0;
  margin-bottom: 1rem;
}
.loader {
  /* Styles to center the loading image */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999; /* Ensure it's on top of other content */
}
.loader img {
  /* Additional styles for the loading image */
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
}
</style>
