<template>
  <div class="notification new_notification">
    <div class="notification__contents">
      <img src="@/assets/images/check-circle.svg" alt="Checkmark"/>
      <p class="notification__copy" v-if="message">{{ message }}</p>
    </div>
    <button class="notification__close-button" @click="setGlobalNotification(null)">
    <img class="notification__close-icon" src="@/assets/images/close-no-bg_new.svg" alt="Close mark" />
    </button>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'NotificationNew',
  props: {
    message: {
      type: String,
      default: null
    }
  },
  methods: {
    ...mapMutations(['setGlobalNotification'])
  }
}
</script>

<style lang="scss" scoped>
.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 10px;
  width: 582px;
  max-width: 80%;
  background-color: $tt-green-light;
  color: $tt-brand-green;
  text-align: center;

  &__contents {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__copy {
    font-weight: 500;
    font-size: 14px;
  }

  &__close-button {
    cursor: pointer;
    color: grey;
    padding: 5px 10px;
    border: none;
    width: fit-content;
    height: fit-content;
    &:hover{
      background-color: transparent;
    }
  }

  &__close-icon {
    width: 12px;
    height: 12px;
    color: grey;
  }

}
</style>
