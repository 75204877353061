/**
 * This module contains global data and functions related to authenticated users.
 */

import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore'
import { onAuthStateChanged, updateEmail, updatePassword, createUserWithEmailAndPassword } from 'firebase/auth'
import { isEmpty } from 'lodash'
const state = () => ({
  currentUser: null, // The Firebase authenticated user object
  userProfile: {}, // The user's Firestore document, used to store any extra data or documents
  userProfileUnsub: null
})

const getters = {
  currentUser: state => state.currentUser,
  userProfile: state => state.userProfile,
  userFullName: (state) => {
    return `${state?.userProfile?.first_name || ''}${state?.userProfile?.last_name ? ' ' + state.userProfile.lastName : ''}`
  },
  userInitials: (state) => {
    const first = state?.userProfile?.first_name ? state.userProfile.first_name.charAt(0) : 'T'
    const last = state?.userProfile?.last_name ? state.userProfile.last_name.charAt(0) : ''
    return first + last
  }
}

const mutations = {

  setCurrentUser (state, val) {
    state.currentUser = val
  },

  updateCurrentUser (state, val) {
    state.currentUser = { ...state.currentUser, ...val }
  },

  setUserProfile (state, val) {
    state.userProfile = val
  },

  updateUserProfile (state, val) {
    state.userProfile = { ...state.userProfile, ...val }
  },

  setUserProfileUnsub (state, val) {
    state.userProfileUnsub = val
  }
}

const actions = {

  async createFirebaseUserAccount ({ commit, state, dispatch, rootState }, payload) {
    // AUTH STEP 1 - Sign in with email / password
    // @DAVE remember! app.vue needs to check currentUser state and setup userProfiles
    // Switched to async / await
    try {
      const response = await createUserWithEmailAndPassword(rootState.firebaseAuth, this.email, this.password)
      const user = response.user
      localStorage.setItem('uid', response?.user.uid)
      if (user && user.email) {
        // this.$router.push('/quiz')
        const payload = {
          response: response,
          userMessage: 'Send welcome email here!',
          isSuccessful: true
        }

        await dispatch('userAuthCheck')
        commit('setUserPromps', payload)
      } else {
        const errorToTreeAuth = new Error('An Error Occurred in Create New User')
        throw errorToTreeAuth
      }
    } catch (error) {
      // error.code, error.message are returned from FirebaseAuth
      // see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_3 for error types.

      const payload = {
        error: error,
        errorMessage: '',
        isSuccessful: false
      }

      switch (error.code) {
        case 'auth/email-already-in-use':
          payload.errorMessage = 'Email Already In Use. Try signing in!'
          break
        case 'auth/invalid-email':
          payload.errorMessage = 'This email address is invalid.'
          break
        case 'auth/operation-not-allowed':
          payload.errorMessage = 'This operation is not allowed. ' + error
          break
        case 'auth/weak-password':
          payload.errorMessage = 'Password should be at least 6 characters.'
          break
        default:
          payload.errorMessage = 'An unknown sign-up error has occurred. ' + error
          break
      }
      commit('setUserPromps', payload)
    }
  },

  /**
   * Used for route protection, as well as general user authentication checks
   * Resolves if the user is logged in, otherwise rejects.
   * Creating this callable function basically allows us to check auth in a more controllable,
   * predictable way that using a listener, for things like checking before a route, etc...
   */
  userAuthCheck ({ commit, dispatch, state, rootState }) {
    return new Promise((resolve) => {
      const unsubscribe = onAuthStateChanged(rootState.firebaseAuth, async (user) => {
        unsubscribe() // a self calling async function? How does this work? Find an example.

        if (user) {
          commit('setCurrentUser', user)
          // If it's not already in state, get the remote user profile (before direct routes, for example)
          if (!state.userProfile || isEmpty(state.userProfile)) {
            await dispatch('getFirestoreUserProfile') // If we don't already have it, await getting the userProfile
          }
          // Then set up the listener for any future updates
          dispatch('userProfileListener')
          resolve(true)
        } else {
          if (rootState.User.currentUser && rootState.User.currentUser.email) {
            commit('setCurrentUser', rootState.User.currentUser)
          }
          resolve(false)
        }
      })
    })
  },

  /**
   * Sets up a realtime listener for the authenticated user's firebase state.
   *
   * See:
   * https://firebase.google.com/docs/reference/android/com/google/firebase/auth/FirebaseAuth.AuthStateListener
   */
  userProfileListener ({ commit, state, rootState }) {
    if (state.userProfileUnsub != null) {
      return
    }

    const unsub = onSnapshot(doc(rootState.firestore, 'users', state.currentUser.uid), (user) => {
      const userData = user.data()
      if (userData) {
        commit('setUserProfile', userData)
      }
    })

    commit('setUserProfileUnsub', unsub)
  },

  stopUserProfileListener ({ state, commit }) {
    state.userProfileUnsub() // Unsubscribe from the listener
    commit('setUserProfileUnsub', null)
  },

  // TODO - review this implementation
  // AUTH STEP 3 - create initial user data in Firestore if it doesn't already exist
  /**
   * Asynchronously initializes user profile data using provided Firebase user credential data and dispatches an action to update the Firestore user profile.
   * @async
   * @param {Object} [context] - The Vuex context object, containing state, rootState, and dispatch function.
   * @param {UserCredential} userData - User credential data obtained after signing in with Firebase.
   * @returns {Promise<void>} A Promise that resolves once the user profile data is initialized and updated in Firestore.
   */
  async initUserProfileData ({ state, rootState, dispatch }, userData) {
    const payload = {
      first_name: userData.user.displayName,
      last_name: null,
      email_address: userData.user.email,
      email_verified: userData.user.emailVerified || null,
      displayname: userData.user.displayName || null,
      zip_main: null,
      user_type: null,
      social_id: null,
      phone_number_main: null,
      firestore_reference_userId: rootState.firebaseAuth.currentUser.uid || rootState.User.currentUser.uid,
      date_createdAt: userData.user.metadata.createdAt ?? Date.now(),
      date_creationTime: userData.user.metadata.creationTime ?? Date.now(),
      date_deleted: 'populateViaFirebaseLater (use server time)',
      isDeleted: null
    }
    await dispatch('updateFirestoreUserProfile', payload)
  },

  // In the listener situation, it's best to just use the remote document as the "source of truth"
  // - the state will update whenever it's updated
  /**
   * Get the target user profile once (not a listener) - used to load user data before a direct route
   */
  async getFirestoreUserProfile ({ commit, state, rootState }) {
    const user = await getDoc(doc(rootState.firestore, 'users', rootState.firebaseAuth.currentUser.uid))
    commit('setUserProfile', user.data())
  },

  // Added a payload argument here so you can pass in whatever to update the Firestore doc
  async updateFirestoreUserProfile ({ commit, state, rootState }, payload) {
    try {
      await setDoc(
        doc(rootState.firestore, 'users', rootState.User.currentUser.uid),
        payload,
        { merge: true } // merge: true will merge if doc exists, otherwise create
      )
    } catch (err) {
      commit('setGlobalError', 'Sorry, there was an error updating your user profile.', { root: true })
    }
  },

  async updateFirestoreAccountAuthPhoneNumber ({ state, rootState }, payload) {
  },

  /**
   * This updates the Firestore Login Email Account not the UserProfile Email
   */
  async updateFirestoreAccountAuthEmail ({ commit, dispatch, rootState }, payload) {
    try {
      await updateEmail(rootState.firebaseAuth.currentUser, payload.email_address)

      await dispatch(
        'updateFirestoreUserProfile',
        { email_address: payload.email_address }
      )
      return true
    } catch (err) {
      if (err.code === 'auth/email-already-in-use') {
        commit('setGlobalError', 'That email is already in use.', { root: true })
      } else {
        commit('setGlobalError', 'Sorry, there was an error updating your email.', { root: true })
      }

      throw err
    }
  },

  async updateFirestoreAccountAuthPassword ({ commit, state, rootState }, payload) {
    try {
      updatePassword(rootState.firebaseAuth.currentUser, payload.password).then((response) => {
        return true
      })
    } catch (err) {
      commit('setGlobalError', 'Sorry, there was an error updating your password.', { root: true })

      return false
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
