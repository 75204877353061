<template>
  <div class="form__style-radio-1 form__style-radio--overall StyleSelector">
    <div class="form__content--wide">
      <div class="form__item--wide">
        <div class="form__item form__item--radio">
          <div class="form__item--radio__header">
            <label>Draper</label>
            <!-- <h3 class="form__style-radio__subheading">Classic Cool</h3> -->
            <div class="text">
              <p><strong>Classic Cool.</strong> Clean lines, sleek edges, natural woods and darker fabrics. Functionality over form. Timeless minimalism. Inspired by the staff at Sterling Cooper.</p>
            </div>
            <div class="btns-set">
              <div class="redioBTNStyle">
                <input type="radio" name="style-first"
                  v-model="inputValue[0]"
                  value="draper"
                  id="style1-first"
                  class="form__style-radio--ranking--first"
                  @click="radioClicked(0, 'draper')"
                  @input="updateValue"
                >
                <button type="button" class="choiceBTN"><span>1</span>First choice</button>
              </div>
              <div class="redioBTNStyle">
                <input type="radio"
                  v-model="inputValue[1]"
                  value="draper"
                  name="style-second"
                  id="style1-second"
                  class="form__style-radio--ranking--second"
                  @click="radioClicked(1, 'draper')"
                  @input="updateValue"
                >
                <button type="button" class="choiceBTN"> <span>2</span>Second choice</button>
              </div>
            </div>
            <img :src="require('@/assets/images/draper-hero.jpg')">
          </div>
          <div class="form__style-radio__images form__style-radio__images--1 slidechangesWIdth">
            <div v-for="(image, index) in imagesDraper" :key="`draper-${index}`" class="slide">
              <img class="results--available__item__image" :src="require('@/assets/images/draper/' + image)">
            </div>
          </div>
          <!-- <div class="masonryGrid">
            <figure class="grid-item"><img :src="require('@/assets/images/draper-new/draper1.jpg')"></figure>
            <figure class="grid-item"><img :src="require('@/assets/images/draper-new/draper2.jpg')"></figure>
            <figure class="grid-item"><img :src="require('@/assets/images/draper-new/draper3.jpg')"></figure>
            <figure class="grid-item"><img :src="require('@/assets/images/draper-new/draper4.jpg')"></figure>
            <figure class="grid-item"><img :src="require('@/assets/images/draper-new/draper5.jpg')"></figure>
            <figure class="grid-item"><img :src="require('@/assets/images/draper-new/draper6.jpg')"></figure>
            <figure class="grid-item"><img :src="require('@/assets/images/draper-new/draper7.jpg')"></figure>
            <figure class="grid-item"><img :src="require('@/assets/images/draper-new/draper8.jpg')"></figure>
            <figure class="grid-item"><img :src="require('@/assets/images/draper-new/draper9.jpg')"></figure>
            <figure class="grid-item"><img :src="require('@/assets/images/draper-new/draper10.jpg')"></figure>
            <figure class="grid-item"><img :src="require('@/assets/images/draper-new/draper11.jpg')"></figure>
            <figure class="grid-item"><img :src="require('@/assets/images/draper-new/draper12.jpg')"></figure>
            <figure class="grid-item"><img :src="require('@/assets/images/draper-new/draper13.jpg')"></figure>
            <figure class="grid-item"><img :src="require('@/assets/images/draper-new/draper14.jpg')"></figure>
          </div> -->
        </div>
      </div>
      <div class="form__item--wide">
        <div class="form__item form__item--radio">
          <div class="form__item--radio__header">
            <label for="style2">Moody</label>
            <!-- <h3 class="form__style-radio__subheading">Casual Elegance</h3> -->
            <div class="text">
              <p><strong>Casual Elegance.</strong> Softer lines, woven materials & whitewashed woods including oak, teak & ash. Comfort meets style. Warm & welcoming. Furniture you might find on Californication or Big Little Lies.</p>
            </div>
            <div class="btns-set">
            <div class="redioBTNStyle">
              <input type="radio"
                v-model="inputValue[0]"
                value="moody"
                name="style-first"
                id="style2-first"
                class="form__style-radio--ranking--first"
                @click="radioClicked(0, 'moody')"
                @input="updateValue"
              >
              <button type="button" class="choiceBTN"><span>1</span>First choice</button>
            </div>
            <div class="redioBTNStyle">
              <input type="radio"
                v-model="inputValue[1]"
                value="moody"
                name="style-second"
                id="style2-second"
                class="form__style-radio--ranking--second"
                @click="radioClicked(1, 'moody')"
                @input="updateValue"
              >
              <button type="button" class="choiceBTN"> <span>2</span>Second choice</button>
            </div>
          </div>
            <img :src="require('@/assets/images/moody-hero.jpg')">
          </div>
          <div class="form__style-radio__images form__style-radio__images--2 slidechangesWIdth">
            <div v-for="(image, index) in imagesMoody" :key="`moody-${index}`" class="slide">
              <img class="results--available__item__image" :src="require('@/assets/images/moody/' + image)">
            </div>
          </div>
        </div>
      </div>
      <div class="form__item--wide">
        <div class="form__item form__item--radio">
          <div class="form__item--radio__header">
            <label for="style3">Roy</label>
            <!-- <h3 class="form__style-radio__subheading">Edgy Yet Grounded</h3> -->
            <div class="text">
              <p><strong>Edgy Yet Grounded.</strong> Stark, rough, unfinished forms, dark leather, metal and glass, monotone color schemes. Edgy yet grounded. Mainstream masculinity. A style suited for Succession’s Roman or Kendall.</p>
            </div>
            <div class="btns-set">
              <div class="redioBTNStyle">
                <input type="radio"
                  v-model="inputValue[0]"
                  value="roy"
                  name="style-first"
                  id="style3-first"
                  class="form__style-radio--ranking--first"
                  @click="radioClicked(0, 'roy')"
                  @input="updateValue"
                >
                <button type="button" class="choiceBTN"><span>1</span>First choice</button>
              </div>
              <div class="redioBTNStyle">
                <input type="radio"
                  v-model="inputValue[1]"
                  value="roy"
                  name="style-second"
                  id="style3-second"
                  class="form__style-radio--ranking--second"
                  @click="radioClicked(1, 'roy')"
                  @input="updateValue"
                >
                <button type="button" class="choiceBTN"> <span>2</span>Second choice</button>
              </div>
            </div>
            <img :src="require('@/assets/images/roy-hero.jpg')">
          </div>
          <div class="form__style-radio__images form__style-radio__images--3 slidechangesWIdth">
            <div v-for="(image, index) in imagesRoy" :key="`roy-${index}`" class="slide">
              <img class="results--available__item__image" :src="require('@/assets/images/roy/' + image)">
            </div>
          </div>
        </div>
      </div>
      <ValidationWarning :valid="valid" text="Please choose your favorite style." />
    </div>
  </div>
</template>

<script>
import Flickity from 'flickity'
import 'flickity/dist/flickity.min.css'

export default {
  name: 'StyleSelector',
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: true,
      imagesDraper: [
        'draper1.jpg',
        'draper2.jpg',
        'draper3.jpg',
        'draper4.jpg',
        'draper5.jpg',
        'draper6.jpg',
        'draper7.jpg',
        'draper8.jpg',
        'draper9.jpg',
        'draper10.jpg',
        'draper11.jpg',
        'draper12.jpg',
        'draper13.jpg',
        'draper14.jpg',
        'draper15.jpg'
      ],
      imagesMoody: [
        'moody1.jpg',
        'moody2.jpg',
        'moody3.jpg',
        'moody4.jpg',
        'moody5.jpg',
        'moody6.jpg',
        'moody7.jpg',
        'moody8.jpg',
        'moody9.jpg',
        'moody10.jpg',
        'moody11.jpg',
        'moody12.jpg',
        'moody13.jpg',
        'moody14.jpg',
        'moody15.jpg'
      ],
      imagesRoy: [
        'roy1.jpg',
        'roy2.jpg',
        'roy3.jpg',
        'roy4.jpg',
        'roy5.jpg',
        'roy6.jpg',
        'roy7.jpg',
        'roy8.jpg',
        'roy9.jpg',
        'roy10.jpg',
        'roy11.jpg',
        'roy12.jpg',
        'roy13.jpg',
        'roy14.jpg',
        'roy15.jpg'
      ],
      // inputValue: {},
      inputValue: [],
      radioValue: ''
    }
  },
  mounted () {
    this.slideshow()
    // this.inputValue = this.modelValue || {}
    this.inputValue = this.modelValue || []
  },
  methods: {
    radioClicked (index, style) {
      // Clear the radio if an item is clicked again
      if (this.inputValue[index] === style) {
        this.inputValue.splice(index, 1)
        this.updateValue()
      }
    },
    updateValue () {
      this.$emit('update:modelValue', this.inputValue)
    },
    validate () {
      if (!this.required) {
        this.valid = true
      } else {
        /* eslint-disable-next-line no-unneeded-ternary */
        // only the 1st choice is required
        this.valid = !!this.modelValue[0]
      }
      return this.valid
    },
    slideshow () {
      var args = {
        imagesLoaded: true,
        wrapAround: true,
        freeScroll: true,
        pageDots: false,
        prevNextButtons: false
      }
      // eslint-disable-next-line no-unused-vars
      const productImagesDraper = new Flickity('.form__style-radio__images--1', args)
      // eslint-disable-next-line no-unused-vars
      const productImagesMoody = new Flickity('.form__style-radio__images--2', args)
      // eslint-disable-next-line no-unused-vars
      const productImagesRoy = new Flickity('.form__style-radio__images--3', args)
    }
  },
  watch: {
    modelValue: {
      handler () {
        this.validate()
      },
      deep: true
    }
  }
}
</script>
<style>
.hidden-radio {
    display: none;
  }
  .slidechangesWIdth .flickity-cell {
    width: auto;
}
@media (max-width: 583px){
  .slidechangesWIdth .flickity-cell {
      width: auto !important;
  }
}
@media (max-width: 992px){
  .slidechangesWIdth .flickity-cell {
      width: auto !important;
  }
}
</style>
