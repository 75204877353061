import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import mitt from 'mitt'
import { upperFirst, camelCase } from 'lodash'
import VueQRCodeComponent from 'vue-qrcode-component'
import { createGtm } from '@gtm-support/vue-gtm'
import Hotjar from 'vue-hotjar'

const emitter = mitt()
const vueApp = createApp(App).use(store).use(router)
// Import all form UI components
const formComponents = require.context(
  '@/components/quiz/questions',
  true,
  /[A-Z]\w+\.vue$/
)

formComponents.keys().forEach((fileName) => {
  const componentConfig = formComponents(fileName)
  const componentName = upperFirst(
    camelCase(
      fileName.split('/').pop().replace(/\.\w+$/, '')
    )
  )
  vueApp.component(
    componentName,
    componentConfig.default || componentConfig
  )
})
vueApp.component('VueQRCodeComponent', VueQRCodeComponent)

process.env.VUE_APP_GTM_ID && vueApp.use(
  createGtm({
    id: process.env.VUE_APP_GTM_ID ?? '',
    vueRouter: router
  })
)

vueApp.use(Hotjar, {
  id: '3797742',
  isProduction: process.env.VUE_APP_ENV === 'production' || true
})

// Add global Vue variables here to access at this.$target in components
// i.e. this.$router.push('/')
vueApp.config.globalProperties.$router = router
vueApp.config.globalProperties.emitter = emitter
vueApp.mount('#app')
