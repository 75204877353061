import { createStore } from 'vuex'
import User from './modules/user'
import Surveys from './modules/surveys'
import Utilities from './modules/utilities'
import router from '../router'

import { initializeApp, getApp } from 'firebase/app'
import { getFirestore, collection, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAuth, connectAuthEmulator, signOut } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import * as firebaseJSON from '../../firebase.json'

// Firebase is initialized here so that it's components can be used throughout the app
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)
const db = getFirestore(firebaseApp)

// db.settings({ timestampsInSnapshots: true })
const usersCollection = collection(db, 'users')
const auth = getAuth()
const functions = getFunctions(getApp())

// Connect emulators

if (process.env.VUE_APP_USE_FIREBASE_EMULATORS === 'true') {
  const firebaseEmulator = firebaseJSON.emulators

  connectFunctionsEmulator(functions, 'localhost', firebaseEmulator.functions.port)
  connectFirestoreEmulator(db, 'localhost', firebaseEmulator.firestore.port)
  connectAuthEmulator(auth, 'http://localhost:' + firebaseEmulator.auth.port)
}

export default createStore({
  // plugins: [createLogger()], // enable for detailed Vuex logs
  state: {
    showDebug: process.env.VUE_APP_SHOW_DEBUG === 'true',
    includeUtilities: process.env.VUE_APP_INCLUDE_UTILITIES === 'true',
    firebaseAuth: auth,
    firestore: db,
    analytics: analytics,
    fbFunctions: functions,
    usersCollection: usersCollection,
    totalSurveySteps: 14,
    modals: {
      showSignup: false,
      showGenericPopUp: false
    },
    completedOrder: null,
    pageLoading: false,
    clipboardLoader: false,
    invertNav: false,
    globalNotification: null,
    globalError: null,
    globalErrorShown: false,
    selectionServiceFee: 149,
    selectionDiscountThreshold: 8000,

    selectedItems: [], // Chosen items in the Selection view
    // Used throughout the app for state selection dropdown
    usStates: [
      {
        name: 'Select a State',
        abbreviation: null
      },
      {
        name: 'Alabama',
        abbreviation: 'AL'
      },
      {
        name: 'Alaska',
        abbreviation: 'AK'
      },
      {
        name: 'American Samoa',
        abbreviation: 'AS'
      },
      {
        name: 'Arizona',
        abbreviation: 'AZ'
      },
      {
        name: 'Arkansas',
        abbreviation: 'AR'
      },
      {
        name: 'California',
        abbreviation: 'CA'
      },
      {
        name: 'Colorado',
        abbreviation: 'CO'
      },
      {
        name: 'Connecticut',
        abbreviation: 'CT'
      },
      {
        name: 'Delaware',
        abbreviation: 'DE'
      },
      {
        name: 'District Of Columbia',
        abbreviation: 'DC'
      },
      {
        name: 'Federated States Of Micronesia',
        abbreviation: 'FM'
      },
      {
        name: 'Florida',
        abbreviation: 'FL'
      },
      {
        name: 'Georgia',
        abbreviation: 'GA'
      },
      {
        name: 'Guam',
        abbreviation: 'GU'
      },
      {
        name: 'Hawaii',
        abbreviation: 'HI'
      },
      {
        name: 'Idaho',
        abbreviation: 'ID'
      },
      {
        name: 'Illinois',
        abbreviation: 'IL'
      },
      {
        name: 'Indiana',
        abbreviation: 'IN'
      },
      {
        name: 'Iowa',
        abbreviation: 'IA'
      },
      {
        name: 'Kansas',
        abbreviation: 'KS'
      },
      {
        name: 'Kentucky',
        abbreviation: 'KY'
      },
      {
        name: 'Louisiana',
        abbreviation: 'LA'
      },
      {
        name: 'Maine',
        abbreviation: 'ME'
      },
      {
        name: 'Marshall Islands',
        abbreviation: 'MH'
      },
      {
        name: 'Maryland',
        abbreviation: 'MD'
      },
      {
        name: 'Massachusetts',
        abbreviation: 'MA'
      },
      {
        name: 'Michigan',
        abbreviation: 'MI'
      },
      {
        name: 'Minnesota',
        abbreviation: 'MN'
      },
      {
        name: 'Mississippi',
        abbreviation: 'MS'
      },
      {
        name: 'Missouri',
        abbreviation: 'MO'
      },
      {
        name: 'Montana',
        abbreviation: 'MT'
      },
      {
        name: 'Nebraska',
        abbreviation: 'NE'
      },
      {
        name: 'Nevada',
        abbreviation: 'NV'
      },
      {
        name: 'New Hampshire',
        abbreviation: 'NH'
      },
      {
        name: 'New Jersey',
        abbreviation: 'NJ'
      },
      {
        name: 'New Mexico',
        abbreviation: 'NM'
      },
      {
        name: 'New York',
        abbreviation: 'NY'
      },
      {
        name: 'North Carolina',
        abbreviation: 'NC'
      },
      {
        name: 'North Dakota',
        abbreviation: 'ND'
      },
      {
        name: 'Northern Mariana Islands',
        abbreviation: 'MP'
      },
      {
        name: 'Ohio',
        abbreviation: 'OH'
      },
      {
        name: 'Oklahoma',
        abbreviation: 'OK'
      },
      {
        name: 'Oregon',
        abbreviation: 'OR'
      },
      {
        name: 'Palau',
        abbreviation: 'PW'
      },
      {
        name: 'Pennsylvania',
        abbreviation: 'PA'
      },
      {
        name: 'Puerto Rico',
        abbreviation: 'PR'
      },
      {
        name: 'Rhode Island',
        abbreviation: 'RI'
      },
      {
        name: 'South Carolina',
        abbreviation: 'SC'
      },
      {
        name: 'South Dakota',
        abbreviation: 'SD'
      },
      {
        name: 'Tennessee',
        abbreviation: 'TN'
      },
      {
        name: 'Texas',
        abbreviation: 'TX'
      },
      {
        name: 'Utah',
        abbreviation: 'UT'
      },
      {
        name: 'Vermont',
        abbreviation: 'VT'
      },
      {
        name: 'Virgin Islands',
        abbreviation: 'VI'
      },
      {
        name: 'Virginia',
        abbreviation: 'VA'
      },
      {
        name: 'Washington',
        abbreviation: 'WA'
      },
      {
        name: 'West Virginia',
        abbreviation: 'WV'
      },
      {
        name: 'Wisconsin',
        abbreviation: 'WI'
      },
      {
        name: 'Wyoming',
        abbreviation: 'WY'
      }
    ]
  },
  getters: {
    getModalStatus: state => state.modals,
    getModalSignUp: state => state.modals.showSignup,
    getModalAdditionalInfo: state => state.modals.showAdditionalInfo,
    getModalOffer: state => state.modals.showOffer
  },
  mutations: {
    setPageLoading (state, val) {
      state.pageLoading = val
    },
    setClipboardLoader (state, val) {
      state.clipboardLoader = val
    },
    setInvertNav (state, val) {
      state.invertNav = val
    },
    setGlobalNotification (state, val) {
      state.globalNotification = typeof val === 'string' ? { val } : val
      setTimeout(() => {
        state.globalNotification = null
      }, 2000)
    },
    setGlobalError (state, val) {
      if (val) {
        state.globalError = val
        state.globalErrorShown = true
      } else {
        state.globalErrorShown = false
      }
    },
    setModals (state, val) {
      state.modals = { ...state.modals, ...val }
    },
    setModalAdditionalInfo (state, val) {
      state.modals.showAdditionalInfo = val
    },
    setModalOffer (state, val) {
      state.modals.showOffer = val
    },
    setModalSignup (state, val) {
      state.modals.showSignup = val
    },
    setModalGeneric (state, val) {
      state.modals.showGenericPopUp = val
    },
    setCompletedOrder (state, val) {
      state.completedOrder = val
    },

    addSelectedItem (state, payload) {
      state.selectedItems.push(payload)
    },
    removeSelectedItem (state, index) {
      state.selectedItems.splice(index, 1)
    },
    resetSelectedItems (state) {
      state.selectedItems = []
    }
  },
  actions: {
    /**
     * Sign the user out of Firebase authentication
     * Also clears any Vuex state related to that user
     */
    async logout ({ state, commit, dispatch }) {
      try {
        await signOut(state.firebaseAuth)
        localStorage.removeItem('uid')
        // Stop listeners FIRST, so we don't get Firestore permissions errors
        dispatch('stopUserProfileListener')
        commit('setUserProfile', null)
        commit('setCurrentUser', null)
        // Clear survey & selection related data in state
        commit('setMySurveys', [])
        commit('setTargetSurvey', null)
        commit('setCompletedOrder', null)
        commit('setMySelections', null)
        commit('setChosenFurnitureData', null)
        commit('resetSelectedItems')
        localStorage.clear()

        router.push('/') // keep any router pushes to the end of any actions.
      } catch (err) {
        commit('setGlobalError', 'Sorry, an error occurred during logout.', { root: true })
      }
    }
  },
  modules: {
    User,
    Surveys,
    Utilities
  }
})
