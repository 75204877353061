
<template>
  <AdvancedImage
    :cldImg="img"
    style="max-width: 100%"
    :plugins="plugins"
  />
</template>

<script>
import { AdvancedImage, placeholder } from '@cloudinary/vue'
import { Cloudinary } from '@cloudinary/url-gen'
import { format, quality } from '@cloudinary/url-gen/actions/delivery'

const cld = new Cloudinary({
  cloud: {
    cloudName: 'totree'
  }
})

export default {
  name: 'CloudinaryImage',
  components: {
    AdvancedImage
  },
  props: {
    publicId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      plugins: [placeholder()]
    }
  },
  computed: {
    img: function () {
      return cld
        .image(this?.publicId)
        .delivery(format('auto'))
        .delivery(quality('auto:best'))
    }

  }
}

</script>
