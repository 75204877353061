<template>
  <fieldset class="form__question textarea-div">
    <div class="form__content">
      <legend class="quiz__subheading" :class="{ 'meta': metaTitle }">{{ question.question }}</legend>
      <div v-if="question.text" class="text" v-html="question.text"></div>
      <div class="form__item form__item--textarea">
        <textarea
          :name="question.answerRefId"
          :id="question.answerRefId"
          v-model="inputValue"
          @input="updateValue"
          :placeholder="placeholder"
        />
      </div>
      <ValidationWarning :valid="valid" />
    </div>
  </fieldset>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    question: {
      type: Object,
      default: () => {}
    },
    metaTitle: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      valid: true,
      inputValue: ''
    }
  },
  mounted () {
    this.inputValue = this.modelValue || ''
  },
  methods: {
    updateValue () {
      this.$emit('update:modelValue', this.inputValue)
    },
    validate () {
      this.valid = !(this.required && !this.modelValue)
      return this.valid
    }
  },
  watch: {
    modelValue (value) {
      this.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
.meta {
  @include meta-text;
  margin-bottom: var(--gutter) !important;
  font-size: 0.75rem !important;
}
</style>
