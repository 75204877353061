<template>
  <div class="form__question" :class="{ loading: clipboardLoader }">
    <div class="form__content">
      <div class="payment__section">
        <!-- <h3 class="payment__heading payment__heading--section">Delivery Zip Code</h3> -->
        <div class="text">
          <p>Where’s your furniture headed?</p>
        </div>
        <div class="form__item form__item--text">
          <input
            type="text"
            pattern="\d*"
            placeholder="Delivery zip code"
            v-model="shippingAddressZip"
            class="hide-arrows"
          >
        </div>
      </div>

      <div v-if:="errorMessages" class="warning">
        <!-- {{this.errorMessages}} -->
        <span class="closeicon"><i class="icon-close"></i></span>
        <div>
          <h5>There was a problem with your submission.</h5>
          <p class="errorsub"><span></span>
            {{ this.errorMessages }}
          </p>
        </div>
      </div>
    </div>
    <Loader :visible="loading" fixed blocking />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import Loader from '@/components/Loader'
import { getStateFromZipUS } from '@/assets/scripts/helpers'

export default {
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Loader
  },

  data () {
    return {
      loading: false,
      shippingAddressZip: null,
      checkPhone: {
        isValid: false
      },
      errorMessages: ''
    }
  },

  computed: {
    ...mapState(['usStates', 'clipboardLoader']),
    ...mapGetters(['userProfile', 'targetSurvey'])
  },

  async mounted () {
    // const mountedStartTime = new Date().getTime()
    // this.setClipboardLoader(true)
    this.shippingAddressZip = this.targetSurvey?.projectAddress?.zip || this.userProfile?.shipping_zip || ''
    // We want to show the clipboard loader for a minimum of 2500ms
    // const mountedEndTime = new Date().getTime()
    // const timeDiff = mountedEndTime - mountedStartTime
    // let delay = 0
    // const minTimeShown = 1500
    // if (timeDiff < minTimeShown) {
    //   delay = minTimeShown - timeDiff
    // }
    // setTimeout(() => {
    //   this.setClipboardLoader(false)
    // }, delay)
  },
  methods: {
    ...mapMutations(['setTargetSurvey', 'setClipboardLoader']),
    ...mapActions(['updateFirestoreUserProfile', 'updateFirestoreCurrentQuiz']),
    changeText () {
      this.textToDisplay = 'New Text!'
      this.className = 'new-class'
    },
    validate () {
      // Strip out extra characters
      this.shippingAddressZip = this.shippingAddressZip.replace(/\D/g, '')

      // Go ahead and get the state to validate that it's a real zip
      const stateFromZip = getStateFromZipUS(this.shippingAddressZip)

      if (this.required && (!this.shippingAddressZip || !stateFromZip || stateFromZip === 'none')) {
        this.errorMessages = 'Please enter a valid shipping zip code.'
        return false
      }
      return true
    },

    /**
     * Update the project address for the current survey
     */
    async updateProjectZip () {
      const stateFromZip = getStateFromZipUS(this.shippingAddressZip)

      // If the user hasn't already paid, we need to validate the address before proceeding...
      if (!this.alreadyPaid && !this.isAddressDataValid()) {
        if (localStorage.getItem('survey-start-with-login')) {
          localStorage.removeItem('survey-start-with-login')
          throw new Error('')
        } else {
          throw new Error('Invalid address!')
        }
      }

      const projectZipState = {
        projectAddress: {
          state: stateFromZip,
          zip: this.shippingAddressZip
        }
      }
      if (!this.userProfile.shipping_state || !this.userProfile.shipping_zip) {
        await this.updateFirestoreUserProfile({
          shipping_state: stateFromZip,
          shipping_zip: this.shippingAddressZip
        })
      }
      await this.updateFirestoreCurrentQuiz(projectZipState)
      // Update the survey in state with the project zip / state
      this.setTargetSurvey({
        ...this.targetSurvey,
        ...projectZipState
      })
    },

    isAddressDataValid () {
      this.errorMessages = ''

      if (!this.shippingAddressZip) {
        this.errorMessages = 'Please enter the Zip Code you will be shipping to.'

        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.form__question {
  &.loading {
    height: 0;
    overflow: hidden;
    opacity: 0;

    @include breakpoint($s) {
      height: calc(100vh - 380px);
    }
  }
}

.warning {
  grid-column: span 12;
}
.payment__section {
  display: grid;
  grid-column: span 12;
}
.payment__copy {
  font-size: 1.25rem;
  line-height: 1.5rem;
  grid-column: span 12;

  &--list {
    list-style: disc;
    padding-left: var(--gutter);
    margin: var(--leading-h2) 0;
  }

  &--center {
    text-align: center;
  }

  strong {
    font-weight: 700;
  }
}
.payment__heading {
  font-family: 'GT America Regular';
  font-size: 1.5rem !important;
  line-height: 2rem;
  font-weight: 300;
  color: $tt-green;
  grid-column: span 12;
  margin-bottom: var(--leading-h2);

  &--section {
    text-align: center;
  }
}

.form__stripe {
  width: 100%;
  grid-column: span 12;
}
</style>
