<template>
  <transition name="fade-fast">
    <div v-if="visible" class="loader" :class="{ 'fixed': fixed, 'blocking': blocking }">
      <div class="overlay" :style="`opacity: ${ opacity }`" />
      <ProgressPie :indeterminateLoader="indeterminate" />
      <transition name="fade" mode="out-in">
        <p
          v-if="message"
          class="loader__status-text"
          :key="message"
        >
          {{ message }}
        </p>
      </transition>
    </div>
  </transition>
</template>

<script>
import ProgressPie from '@/components/ProgressPie'
export default {
  components: {
    ProgressPie
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: null
    },
    indeterminate: {
      type: Boolean,
      default: true
    },
    progress: {
      type: Number,
      default: 0.0
    },
    opacity: {
      type: Number,
      default: 0.9
    },
    fixed: {
      type: Boolean,
      default: false
    },
    blocking: {
      type: Boolean,
      default: false
    },
    speed: {
      type: Number,
      default: 125
    }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  @include fill-parent;
  pointer-events: none;
  z-index: 600;

  &.fixed {
    position: fixed;
  }

  &.blocking {
    pointer-events: all;
  }

  &__status-text {
    @include meta-text;
    @include center-in-parent;
    top: calc(50% + 90px);
    transform: translateX(-50%) translateY(0);
    max-width: 220px;
    text-align: center;

    @include breakpoint($s) {
      top: calc(50% + 68px);
    }
  }

  .overlay {
    @include fill-parent;
    background-color: white;
  }

  ::v-deep .progress-pie {
    @include center-in-parent;
  }
}
</style>
