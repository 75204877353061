<template>
  <transition name="warning-slide-down" mode="out-in">
    <div v-if="!valid" class="form-validation warning">
      <!-- {{ text }} -->
      <span class="closeicon"><i class="icon-close"></i></span>
      <div>
        <h5>There was a problem with your submission.</h5>
        <p class="errorsub"><span></span>
          {{ text }}
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ValidationWarning',
  props: {
    text: {
      type: String,
      default: 'This response is required.'
    },
    valid: {
      type: Boolean,
      default: true
    }
  }
}
</script>
