<template>
  <div
    class="progress-pie"
    :class="{ indeterminate: indeterminateLoader }"
  >
    <img src="@/assets/images/pending.svg" class="progress-pie__background" />
    <div class="progress-pie__slices">
      <div
        v-for="(segment, index) in totalSegments"
        :key="`segment-${index}`"
        class="progress-pie__slice"
        :class="{ 'visible': index < activeSegments }"
      >
        <img
          src="@/assets/images/pie-slice.svg"
          class="progress-pie__slice__image"
          :style="`transform: rotateZ(${(360 / totalSegments) * index}deg)`"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // Progress should be 0.0 - 1.0
    progress: {
      type: Number,
      default: 0.0
    },

    indeterminateLoader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      totalSegments: 8
    }
  },
  computed: {
    activeSegments () {
      return parseInt(this.progress * this.totalSegments)
    }
  }
}
</script>

<style lang="scss" scoped>
$size: 120px;
$sizeMobile: 96px;
.progress-pie {
  position: absolute;
  width: $size;
  height: $size;

  @include breakpoint($s) {
    width: $sizeMobile;
    height: $sizeMobile;
  }

  // Staggered animation delay with SCSS loop
  &.indeterminate {
    @for $i from 0 through 8 {
      .progress-pie__slice:nth-child(#{$i + 1}) {
        animation: appear 0.8s infinite;
        animation-delay: $i * 100ms;
      }
    }
  }

  &__slices {
    width: $size;
    height: $size;

    @include breakpoint($s) {
      width: $sizeMobile;
      height: $sizeMobile;
    }
  }

  &__slice {
    @include center-in-parent;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;

    &.visible {
      opacity: 1;
    }

    &__image {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes appear {
    0% {
      opacity: 0
    }
    10% {
      opacity: 1
    }
  }
}
</style>
